import axios, { AxiosRequestConfig } from 'axios';
import { eventHeaders } from '../../common/auth';
import {
  NotificationCreationRequest,
  NotificationDetail,
  NotificationType,
  Notification
} from '../../interface/Notification';
import { API_BASE_URL } from '../constant';

export const fetchNotificationTypes = async (): Promise<NotificationType[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.get(`${API_BASE_URL}/notifications/type`, {
    ...headers
  });

  return response.data.types;
};

export const postNotification = async (
  request: NotificationCreationRequest
): Promise<number> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/notifications`,
    request,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data.id;
};

export const fetchNotifications = async (
  limit: number,
  offset: number,
  isAll?: boolean
): Promise<Notification[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.get(`${API_BASE_URL}/notifications`, {
    params: { limit, offset, isAll },
    ...headers
  });
  return response.data.notifications;
};

export const fetchNotificationDetail = async (
  notificationId: number
): Promise<NotificationDetail | undefined> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  try {
    const response = await axios.get(
      `${API_BASE_URL}/notifications/${notificationId}`,
      {
        ...headers
      }
    );
    return response.data.notificationDetail;
  } catch (e) {
    if (
      axios.isAxiosError(e) &&
      e.response &&
      (e.response.status === 404 || e.response.status === 400)
    ) {
      return undefined;
    } else if (
      axios.isAxiosError(e) &&
      e.response &&
      e.response.status >= 400
    ) {
      throw new Error(e.message);
    }
  }
};

export const updateNotification = async (
  request: NotificationCreationRequest,
  id: number
) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/notifications/${String(id)}`,
    request,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data.id;
};
