import { Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import { SortCondition } from '../../interface/AnswersTable';

type HeaderSortButtonsProps = {
  sortTarget: number;
  sortConditions: SortCondition[];
  setSortConditions: Dispatch<SetStateAction<SortCondition[]>>;
  setPage: Dispatch<SetStateAction<number>>;
  fetchAnswers: Function;
};

const HeaderSortButtons: React.FC<HeaderSortButtonsProps> = (props) => {
  const makeNewSortCondition = (
    sortConditions: SortCondition[],
    isAsc: boolean
  ): SortCondition[] => {
    const sortTarget: number = props.sortTarget;
    const targetSortCondition: SortCondition | undefined = sortConditions.find(
      (sortCondition: SortCondition) => sortCondition.sortTarget === sortTarget
    );

    // 今かかっているソート条件のボタンを押されたときはソート条件を外す
    if (
      targetSortCondition !== undefined &&
      targetSortCondition.isAsc === isAsc
    ) {
      return sortConditions.filter(
        (sortCondition: SortCondition) =>
          sortCondition.sortTarget !== sortTarget
      );
    } // 対象列にソートがかかっており、逆順でソートする場合
    else if (targetSortCondition !== undefined) {
      return sortConditions.map((sortCondition: SortCondition) => {
        if (sortCondition.sortTarget === sortTarget)
          return { sortTarget, isAsc };

        return sortCondition;
      });
    }

    // 単純にソート列を追加する
    return [...sortConditions, { sortTarget, isAsc }];
  };

  const onClickSortButton = (isAsc: boolean) => () => {
    const newSortConditions: SortCondition[] = makeNewSortCondition(
      props.sortConditions,
      isAsc
    );
    props.setSortConditions(newSortConditions);
    props.setPage(0);
    props.fetchAnswers(0, newSortConditions);
  };

  const usedBySort = (isAsc: boolean): boolean => {
    return (
      props.sortConditions.find(
        (sortCondition: SortCondition) =>
          sortCondition.sortTarget === props.sortTarget &&
          sortCondition.isAsc === isAsc
      ) !== undefined
    );
  };

  const sortOrder = (isAsc: boolean): JSX.Element => {
    const index: number = props.sortConditions.findIndex(
      (sortCondition: SortCondition) =>
        sortCondition.sortTarget === props.sortTarget &&
        sortCondition.isAsc === isAsc
    )!;

    return <sup>{index + 1}</sup>;
  };

  return (
    <Box sx={{ whiteSpace: 'nowrap' }} className="sort-buttons">
      <ButtonWithToolTip
        icon={<ArrowUpwardIcon />}
        title="昇順"
        onClick={onClickSortButton(true)}
        className="sort-asc-button"
        sx={{ visibility: usedBySort(true) ? 'visible' : 'hidden' }}
      />
      {usedBySort(true) ? sortOrder(true) : <></>}
      <ButtonWithToolTip
        icon={<ArrowDownwardIcon />}
        title="降順"
        onClick={onClickSortButton(false)}
        className="sort-desc-button"
        sx={{ visibility: usedBySort(false) ? 'visible' : 'hidden' }}
      />
      {usedBySort(false) ? sortOrder(false) : <></>}
    </Box>
  );
};

export default HeaderSortButtons;
