import { ConditionOption, FilterCondition } from '../interface/AnswersTable';

type ConditionMaster = {
  condition: ConditionOption;
  name: string;
  isForNumber: boolean;
};

export const CONDITIONS: ConditionMaster[] = [
  { condition: 'like', name: '含む', isForNumber: false },
  { condition: 'notLike', name: '含まない', isForNumber: false },
  { condition: 'strictEqual', name: '等しい', isForNumber: false },
  { condition: 'strictNotEqual', name: '等しくない', isForNumber: false },
  { condition: 'greaterThan', name: 'より大きい', isForNumber: true },
  { condition: 'orMore', name: '以上', isForNumber: true },
  { condition: 'orLess', name: '以下', isForNumber: true },
  { condition: 'lessThan', name: 'より小さい', isForNumber: true }
];

const FILTER_CONDITION_PREFIX: string = `filterConditions_`;
export const getFilterConditionKey = (hash: string): string =>
  FILTER_CONDITION_PREFIX + hash;

export const createNeatFilterConditions = (
  filterConditions: FilterCondition[]
): FilterCondition[] => {
  const neatFilterConditions = filterConditions.filter(
    (filterCondition: FilterCondition) =>
      filterCondition.filterTarget !== 0 &&
      filterCondition.value !== '' &&
      filterCondition.value !== undefined
  );
  return neatFilterConditions;
};

const isForNumberCondition = (condition: ConditionOption): boolean => {
  const targetCondition: ConditionMaster | undefined = CONDITIONS.find(
    (c) => c.condition === condition
  );

  if (targetCondition === undefined) {
    throw new Error('Invalid condition');
  }

  return targetCondition.isForNumber;
};

export const isInvalidNumberCondition = (
  filterCondition: FilterCondition
): boolean => {
  try {
    return (
      isForNumberCondition(filterCondition.condition) &&
      isNaN(Number(filterCondition.value))
    );
  } catch (e) {
    return true;
  }
};

export const parseQueryParams = (
  queryParams: URLSearchParams
): FilterCondition[] => {
  const filters: FilterCondition[] = [];
  queryParams.forEach((value, key) => {
    const match = key.match(/filter\[(\d+)\]\[(\w+)\]/);
    if (match) {
      const index = parseInt(match[1], 10);
      // 条件は最大5つ設定可能
      if (index >= 5) return;
      const field = match[2];
      if (!filters[index]) {
        filters[index] = { filterTarget: 0, condition: 'like', value: '' };
      }
      switch (field) {
        case 'filterTarget':
          filters[index].filterTarget = parseInt(value, 10);
          break;
        case 'condition':
          filters[index].condition = value as ConditionOption;
          break;
        case 'value':
          filters[index].value = value;
          break;
      }
    }
  });

  return createNeatFilterConditions(filters);
};
