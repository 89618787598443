import axios, { AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';
import {
  Answer,
  AnswerMaster,
  PreviousAnswerQueryParam,
  AnswerMasterForEdit,
  AnswerPostResponse,
  ChunkUpsertAnswerRequest,
  ValidatingAnswer
} from '../../interface/Answer';
import {
  AnswersTableResponse,
  FilterCondition,
  SortCondition
} from '../../interface/AnswersTable';
import { eventHeaders } from '../../common/auth';
import { API_BASE_URL } from '../constant';

export const fetchPreviousAnswers = async (
  requestParameter: PreviousAnswerQueryParam,
  isSameUser: boolean
): Promise<Answer[] | undefined> => {
  const user = await Auth.currentAuthenticatedUser();
  const userId = user.attributes.email;
  const headers: AxiosRequestConfig = await eventHeaders();

  try {
    const response = isSameUser
      ? await axios.post(
          `${API_BASE_URL}/answer/inheritance/user`,
          {
            userId,
            hash: requestParameter.hash,
            answer:
              requestParameter.questionId !== undefined
                ? {
                    questionId: requestParameter.questionId,
                    itemId: requestParameter.itemId,
                    textAnswer: requestParameter.textAnswer
                  }
                : undefined
          },
          headers
        )
      : await axios.get(`${API_BASE_URL}/answer/inheritance`, {
          ...headers,
          params: requestParameter
        });
    return response.data.answers;
  } catch (e) {
    if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
      return undefined;
    } else if (
      axios.isAxiosError(e) &&
      e.response &&
      e.response.status >= 400
    ) {
      throw new Error(e.message);
    }
  }
};

export const submitNewAnswers = async (
  answerMaster: AnswerMaster
): Promise<AnswerPostResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/answer`,
    answerMaster,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data;
};

export const updateAnswers = async (
  answerMasterForEdit: AnswerMasterForEdit
): Promise<AnswerPostResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/answer`,
    answerMasterForEdit,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data;
};

export const fetchAnswersByMetadataId = async (
  metadataId: number
): Promise<Answer[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.get(`${API_BASE_URL}/answer/${metadataId}`, {
    ...headers
  });
  return response.data.answers;
};

export const fetchAnswers = async (
  questionnaireId: number,
  limit: number,
  offset: number
): Promise<AnswersTableResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.get(`${API_BASE_URL}/answer`, {
    params: { questionnaireId, limit, offset },
    ...headers
  });

  return {
    headers: response.data.headers,
    answers: response.data.answers,
    totalCount: response.data.totalCount
  };
};

export const fetchFilteredAnswers = async (
  questionnaireId: number,
  isUnique: boolean,
  limit: number,
  offset: number,
  filter?: FilterCondition[],
  sort?: SortCondition[]
): Promise<AnswersTableResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.post(
    `${API_BASE_URL}/answer/${
      isUnique ? 'unique' : 'filter'
    }/${questionnaireId}`,
    {
      limit,
      offset,
      filter,
      sort
    },
    headers
  );

  return {
    headers: response.data.headers,
    answers: response.data.answers,
    totalCount: response.data.totalCount
  };
};

export const fetchAnswersByMetadataIds = async (
  metadataIds: number[]
): Promise<ValidatingAnswer[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/answer/chunk`,
    { metadataIds },
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data.answers;
};

export const chunkUpsertAnswers = async (
  request: ChunkUpsertAnswerRequest
): Promise<string> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/answer/chunk`,
    request,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data.message;
};

export const deleteAnswers = async (
  metadataIds: number[],
  userId: string,
  questionnaireId: number
): Promise<number[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.delete(`${API_BASE_URL}/answer`, {
    ...headers,
    data: { metadataIds, userId, questionnaireId }
  });

  return response.data.deleted;
};
