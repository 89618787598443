import axios, { AxiosRequestConfig } from 'axios';
import { Answer, AnswerPostResponse } from '../../interface/Answer';
import { eventHeaders } from '../../common/auth';
import { API_BASE_URL } from '../constant';

export const saveTempAnswer = async (
  questionnaireId: number,
  temporaryAnswers: string,
  keyItemId?: number,
  keyTextAnswer?: string
): Promise<AnswerPostResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.post(
    `${API_BASE_URL}/temporary-answer/${questionnaireId}`,
    {
      temporaryAnswers,
      keyItemId,
      keyTextAnswer
    },
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.data;
};

export const fetchTemporarySavedAnswer = async (
  questionnaireId: number,
  itemId?: number,
  textAnswer?: string
): Promise<Answer[]> => {
  try {
    const headers: AxiosRequestConfig = await eventHeaders();
    const response = await axios.get(
      `${API_BASE_URL}/temporary-answer/${questionnaireId}`,
      {
        ...headers,
        params: { itemId, textAnswer }
      }
    );

    if (response.status === 204) {
      return [];
    }
    return JSON.parse(response.data.temporaryAnswers);
  } catch (e) {
    throw new Error((e as Error).message);
  }
};
