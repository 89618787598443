import axios, { AxiosRequestConfig } from 'axios';
import { ApiUser } from '../../interface/User';
import { eventHeaders } from '../../common/auth';
import { API_BASE_URL } from '../constant';

export const updateAuthorities = async (
  questionnaireIds: number[],
  deleteIds: number[],
  userId: string,
  name: string
) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/authority/user`,
    { questionnaireIds, deleteIds, name, userId },
    headers
  );
  if (response.status >= 400) {
    throw new Error(response.data.message);
  }
  return response.data.ids;
};

export const updateFormAuthorities = async (
  questionnaireId: number,
  users: ApiUser[]
) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/authority/questionnaire/${questionnaireId}`,
    {
      users: users.map((user: ApiUser) => ({
        userId: user.email,
        name: user.name
      }))
    },
    headers
  );
  if (response.status >= 400) {
    throw new Error(response.data.message);
  }
};

export const fetchAuthorizedUsers = async (
  questionnaireId: number
): Promise<ApiUser[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.get(
    `${API_BASE_URL}/authority/questionnaire/${questionnaireId}`,
    {
      ...headers
    }
  );
  return response.data.users.map((user: { userId: string; name: string }) => ({
    name: user.name,
    email: user.userId
  }));
};
