import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AddedQuestion,
  AddedQuestionItem,
  AnswerRangeForApi,
  QuestionCondition,
  QuestionConditionForAPI,
  EditingAnswerRange,
  NewQuestion,
  NewQuestionItem,
  QuestionGroup,
  EditingQuestion,
  EditingQuestionItem,
  QuestionResponse,
  ExistingQuestion
} from '../../interface/Question';
import EditableQuestionnaire from './EditableQuestionnaire';
import { Auth } from 'aws-amplify';
import {
  NewGroup,
  Questionnaire,
  QuestionnaireGetResponse,
  QuestionnaireMetaData
} from '../../interface/Questionnaire';
import { Inheritance } from '../../interface/Inheritance';
import {
  postQuestionnaire,
  fetchQuestions,
  fetchQuestionnaires
} from '../../api';
import { useDispatch } from 'react-redux';
import {
  clearState,
  initialize,
  replaceEditingQuestion
} from '../../redux/slice/QuestionnaireSlice';
import { useSelector } from '../../redux/store';
import {
  createInheritance,
  completelyExpandQuestionResponse
} from '../../common/manageQuestion';
import { extractGroups } from '../../common/formatQuestionForEdit';
import { mergeQuestionWithEditing } from '../../common/manageQuestion';
import _ from 'lodash';

interface QuestionItemIdConvertingTable {
  questionItemId: number;
  questionItemIndex: number;
}

const createNewGroups = (groups: QuestionGroup[]): NewGroup[] =>
  groups.map((group: QuestionGroup) => ({ name: group.name }));

const createQuestionnaire = async (
  questionnaireName: string,
  questions: AddedQuestion[],
  isPublic: boolean,
  inheritance?: Inheritance,
  groups?: NewGroup[]
): Promise<Questionnaire> => {
  const user = await Auth.currentAuthenticatedUser();

  return {
    userId: user.attributes.email,
    name: questionnaireName,
    inheritance,
    isPublic,
    questions,
    groups
  };
};

const FormCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const prevLocation = useRef(location);
  const pathArray: String[] = location.pathname.split('/');
  const questionnaireId: number | undefined =
    pathArray.length === 4 ? Number(pathArray[3]) : undefined;
  const initialQuestionnaireNameFromPreviousPage: string =
    location.state === null
      ? ''
      : (location.state.questionnaireName as string) + '（複製）';

  const inheritance: Inheritance = useSelector(
    (state) => state.questionnaire.inheritance
  );
  const initialInheritance: Inheritance = useSelector(
    (state) => state.questionnaire.initialInheritance
  );
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const initialQuestions: ExistingQuestion[] = useSelector(
    (state) => state.questionnaire.initialQuestions
  );
  const isPublic: boolean = useSelector(
    (state) => state.questionnaire.isPublic
  );
  const initialIsPublic: boolean = useSelector(
    (state) => state.questionnaire.initialIsPublic
  );
  const groups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.groups
  );
  const initialGroups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.initialGroups
  );
  const questionnaireName: string = useSelector(
    (state) => state.questionnaire.name
  );
  const initialQuestionnaireName: string = useSelector(
    (state) => state.questionnaire.initialQuestionnaireName
  );
  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const [questionnaires, setQuestionnaires] = useState<QuestionnaireMetaData[]>(
    [
      {
        id: 0,
        name: '',
        userId: '',
        createdDate: '',
        updatedDate: '',
        isDeleted: false,
        hash: ''
      }
    ]
  );

  // ブラウザのリロードによって読み込まれているか判定する
  const getIsReloadState = (): boolean => {
    const navigationEntries: any =
      window.performance.getEntriesByType('navigation')[0];
    const navigationType = navigationEntries.type;
    if (navigationType) return navigationType === 'reload';
    return false;
  };

  // リロードされた場合アンケート名を取得すためにAPIを実行する
  useEffect(() => {
    // リロードされたか判定
    const isReload = getIsReloadState();
    if (pathArray.length === 4 && isReload) {
      (async () => {
        let offset: number = 0;
        while (1) {
          const response: QuestionnaireGetResponse = await fetchQuestionnaires(
            100,
            offset
          );
          offset += response.questionnaires.length;
          setQuestionnaires([...questionnaires, ...response.questionnaires]);
          if (offset >= response.totalCount) break;
        }
      })();
    }
    // ページ離脱・リロード・ブラウザバック時の制御
    window.addEventListener('beforeunload', handleBeforeUnload, false);
    window.history.pushState(null, '', null);
    window.addEventListener('popstate', handlePopState, false);
    // 他のページに影響しないようクリアする
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const createQuestionItemIdConvertingTable = (
    questions: ExistingQuestion[] | NewQuestion[]
  ): QuestionItemIdConvertingTable[] => {
    // 先頭の質問から、指定した順序のひとつ前までの質問に属する選択肢の合計を数える
    const countTotalSubsetQuestionItemsLength = (
      sliceIndex: number
    ): number => {
      if (sliceIndex <= 0) return 0;
      const slicedQuestion = questions.slice(0, sliceIndex);
      return slicedQuestion.reduce(
        (accumulator, question) =>
          accumulator +
          (question.items === undefined ? 0 : question.items.length),
        0
      );
    };

    return questions
      .flatMap(
        (question: ExistingQuestion | NewQuestion, questionIndex: number) =>
          question.items === undefined
            ? undefined
            : question.items.map((item, itemIndex) => ({
                questionItemId:
                  questionnaireId === undefined ? -item.id : item.id,
                questionItemIndex:
                  countTotalSubsetQuestionItemsLength(questionIndex) +
                  1 +
                  itemIndex
              }))
      )
      .filter(
        (tableInfo: QuestionItemIdConvertingTable | undefined) =>
          tableInfo !== undefined
      );
  };

  const isNegativeZero = (num: number): boolean => {
    num = +num; // cast to number
    if (num) {
      return false;
    }
    let infValue = 1 / num;
    return infValue < 0;
  };

  const getQuestionItemIndex = (
    itemIdConvertingTable: QuestionItemIdConvertingTable[],
    questionCondition: QuestionCondition
  ): number => {
    return (
      itemIdConvertingTable.find(
        (tableInfo: QuestionItemIdConvertingTable) =>
          tableInfo.questionItemId ===
          (questionnaireId === undefined
            ? -questionCondition.questionItemId
            : questionCondition.questionItemId)
      )!.questionItemIndex - 1
    );
  };

  const getQuestionConditionChildGroupId = (
    groups: QuestionGroup[],
    childGroupId: number
  ) => {
    const groupIndex = -groups.findIndex(
      (group: QuestionGroup) => group.id === childGroupId
    );

    // 「-0」を判定し、「0」にして返却する
    if (isNegativeZero(groupIndex)) return groupIndex + 0;

    return groupIndex;
  };

  const save = async () => {
    const saveTarget: NewQuestion[] = mergeQuestionWithEditing(
      questions,
      editingIndex,
      editingQuestion
    );

    const formedSaveTarget: AddedQuestion[] = saveTarget.map(
      (question: NewQuestion, i: number, original: NewQuestion[]) => {
        const items: AddedQuestionItem[] | undefined =
          question.items === undefined
            ? undefined
            : question.items.map((item: NewQuestionItem, index: number) => ({
                ...item,
                priority: i + 1
              }));

        const ranges: AnswerRangeForApi[] | undefined =
          question.ranges === undefined
            ? undefined
            : question.ranges.map((range: EditingAnswerRange) => ({
                min: isNaN(Number(range.minValue))
                  ? undefined
                  : Number(range.minValue),
                max: isNaN(Number(range.maxValue))
                  ? undefined
                  : Number(range.maxValue),
                includesBoundaryValue: range.includesBoundaryValue
              }));

        const itemIdConvertingTable =
          createQuestionItemIdConvertingTable(questions);
        const questionConditions: QuestionConditionForAPI[] | undefined =
          question.questionConditions === undefined
            ? undefined
            : question.questionConditions.map(
                (condition: QuestionCondition) => ({
                  id: condition.id,
                  questionItemIndex: getQuestionItemIndex(
                    itemIdConvertingTable,
                    condition
                  ),
                  childQuestionIndex:
                    condition.childQuestionId === undefined
                      ? undefined
                      : original.findIndex(
                          (target: NewQuestion) =>
                            target.id === condition.childQuestionId
                        ),
                  childGroupIndex:
                    condition.childGroupId === undefined
                      ? undefined
                      : groups!.findIndex(
                          (group: QuestionGroup) =>
                            group.id === condition.childGroupId
                        )
                })
              );

        const extractGroupIndex =
          question.groupId === undefined
            ? undefined
            : Math.abs(question.groupId!);

        return {
          question: question.question,
          type: question.type,
          required: question.required,
          headline: question.headline,
          items,
          ranges,
          questionConditions,
          canInherit: question.canInherit,
          priority: i + 1,
          groupIndex: extractGroupIndex
        };
      }
    );

    const areSomeQuestionsCanInherit: boolean = questions.some(
      (question: EditingQuestion, i: number) => {
        if (i === editingIndex) return editingQuestion.canInherit;
        return question.canInherit;
      }
    );
    const inheritanceIndex: number = questions.findIndex(
      (question: EditingQuestion) => inheritance.questionId === question.id
    );
    const newInheritance: Inheritance | undefined = !areSomeQuestionsCanInherit
      ? undefined
      : createInheritance(inheritance.isSameUser, inheritanceIndex);

    const newQuestionnaire: Questionnaire = await createQuestionnaire(
      questionnaireName,
      formedSaveTarget,
      isPublic,
      newInheritance,
      groups === undefined || groups.length === 0
        ? undefined
        : createNewGroups(groups)
    );

    await postQuestionnaire(newQuestionnaire);
  };

  const canSave = (): boolean => {
    const updatedQuestions: NewQuestion[] = mergeQuestionWithEditing(
      questions,
      editingIndex,
      editingQuestion
    );
    return (
      !_.isEqual(updatedQuestions, initialQuestions) ||
      !_.isEqual(inheritance, initialInheritance) ||
      !_.isEqual(groups, initialGroups) ||
      !_.isEqual(questionnaireName, initialQuestionnaireName) ||
      isPublic !== initialIsPublic
    );
  };

  const canSaveRef = useRef<boolean>(false);
  canSaveRef.current = canSave();

  // ブラウザバックを検知後確認ダイヤログを出す
  const handlePopState = () => {
    if (
      location.pathname === prevLocation.current.pathname &&
      canSaveRef.current
    ) {
      const isDiscardedOK = window.confirm(
        '保存されていないデータは削除されますが、よろしいですか？'
      );
      if (isDiscardedOK) {
        // OKの場合、historyAPIで戻るを実行します。
        window.history.back();
      }
      // キャンセルの場合、 ダミー履歴を挿入して「戻る」を1回分吸収できる状態にする
      window.history.pushState(null, '', null);
    } else {
      window.history.back();
    }
  };

  // ブラウザのリロード・離脱を検知後確認ダイヤログを出す
  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (canSaveRef.current) {
      e.preventDefault();
      // 非推奨だが、ChromeではreturnValueを設定する必要がある
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      e.returnValue = '';
    }
  };

  const initializeQuestionnaire = (fetched: {
    questions: NewQuestion[];
    isPublic: boolean;
    inheritance?: Inheritance;
    groups?: QuestionGroup[];
    name: string;
  }) => dispatch(initialize(fetched));

  const clear = () => dispatch(clearState());

  const replaceEditing = (question: EditingQuestion) =>
    dispatch(replaceEditingQuestion(question));

  const updateInitialStates = async (
    questionnaireId: number,
    questionnaireName: string,
    editingIndex?: number
  ) => {
    const response: QuestionResponse = await fetchQuestions(
      questionnaireId,
      false
    );
    const groups: QuestionGroup[] = extractGroups(response.questions);
    const questions: ExistingQuestion[] = completelyExpandQuestionResponse(
      response.questions
    );

    // 質問ID, グループID、選択肢ID、回答範囲ID、分岐条件IDを新規質問のものに変換
    // 新規の質問ID、選択肢IDの場合、-1, -2, ... と採番する
    // 新規のグループIDの場合、0, -1, -2,... と採番する
    // 新規の回答範囲ID、分岐条件IDの場合は採番されない
    const newGroups: QuestionGroup[] = groups.map(
      (group: QuestionGroup, index: number) => {
        return {
          ...group,
          nextTo: {
            questionId:
              group.nextTo.questionId === undefined
                ? undefined
                : -(
                    questions.findIndex(
                      (question: ExistingQuestion) =>
                        question.id === group.nextTo.questionId
                    ) + 1
                  ),
            groupId:
              group.nextTo.groupId === undefined
                ? undefined
                : -groups.findIndex(
                    (g: QuestionGroup) => g.id === group.nextTo.groupId
                  )
          },
          id: -index
        };
      }
    );
    const itemIdConvertingTable =
      createQuestionItemIdConvertingTable(questions);
    const newQuestionsExceptConditions: NewQuestion[] = questions.map(
      (question: ExistingQuestion, index: number) => ({
        id: -(index + 1),
        question: question.question,
        type: question.type,
        required: question.required,
        headline: question.headline,
        items:
          question.items === undefined
            ? undefined
            : question.items.map(
                (item: EditingQuestionItem): NewQuestionItem => ({
                  id: -itemIdConvertingTable.find(
                    (tableInfo: QuestionItemIdConvertingTable) =>
                      tableInfo.questionItemId === item.id
                  )!.questionItemIndex,
                  name: item.name,
                  isDescription: item.isDescription
                })
              ),
        canInherit: question.canInherit,
        ranges:
          question.ranges === undefined
            ? undefined
            : question.ranges.map(
                (range: EditingAnswerRange): EditingAnswerRange => ({
                  ...range,
                  id: undefined
                })
              ),
        questionConditions: question.questionConditions,
        groupId:
          question.groupId === undefined
            ? undefined
            : -groups.findIndex(
                (group: QuestionGroup) => group.id === question.groupId
              ),
        isOpen: question.isOpen
      })
    );

    const newQuestions: NewQuestion[] = newQuestionsExceptConditions.map(
      (question: NewQuestion) => ({
        ...question,
        questionConditions:
          question.questionConditions === undefined
            ? undefined
            : question.questionConditions.map(
                (questionCondition: QuestionCondition) => ({
                  id: undefined,
                  questionItemId: -itemIdConvertingTable.find(
                    (tableInfo: QuestionItemIdConvertingTable) =>
                      tableInfo.questionItemId ===
                      questionCondition.questionItemId
                  )!.questionItemIndex,
                  childQuestionId:
                    questionCondition.childQuestionId === undefined
                      ? undefined
                      : -(
                          questions.findIndex(
                            (question: ExistingQuestion) =>
                              question.id === questionCondition.childQuestionId
                          ) + 1
                        ),
                  childGroupId:
                    questionCondition.childGroupId === undefined
                      ? undefined
                      : getQuestionConditionChildGroupId(
                          groups,
                          questionCondition.childGroupId
                        )
                })
              )
      })
    );

    const newInheritance: Inheritance | undefined =
      response.inheritance === undefined
        ? undefined
        : {
            ...response.inheritance,
            questionId:
              response!.inheritance.questionId === undefined
                ? undefined
                : -(
                    questions.findIndex(
                      (question: ExistingQuestion) =>
                        question.id === response.inheritance?.questionId
                    ) + 1
                  )
          };

    initializeQuestionnaire({
      questions: newQuestions,
      isPublic: response.isPublic,
      inheritance: newInheritance,
      groups: newGroups,
      name: questionnaireName
    });

    if (editingIndex !== undefined && editingIndex !== -1)
      replaceEditing(questions[editingIndex]);
  };

  useEffect(() => {
    clear();
    // リロードされたか判定
    const isReload = getIsReloadState();
    //直接URLを叩いて遷移した場合はトップページに戻す
    if (
      initialQuestionnaireNameFromPreviousPage === '' &&
      pathArray.length === 4 &&
      !isReload
    ) {
      navigate('/');
    }

    // パスパラメータにアンケートIDが指定されている場合はアンケート情報をDBから取得する
    if (questionnaireId!) {
      const afterReloadGetQuestionnaireName: string =
        questionnaires.find(
          (questionnaire) => questionnaire.id === questionnaireId
        )?.name! + '（複製）';

      updateInitialStates(
        questionnaireId,
        isReload
          ? afterReloadGetQuestionnaireName!
          : initialQuestionnaireNameFromPreviousPage!
      );
    }
  }, [questionnaireId, questionnaires]);

  return <EditableQuestionnaire save={save} />;
};

export default FormCreatePage;
