import axios, { AxiosRequestConfig } from 'axios';
import { ApiUserResponse } from '../../interface/User';
import { eventHeaders } from '../../common/auth';
import { API_BASE_URL } from '../constant';

export const fetchUsers = async (
  limit: number,
  offset: number,
  name?: string
): Promise<ApiUserResponse> => {
  try {
    const headers: AxiosRequestConfig = await eventHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/user`,
      { name, limit, offset },
      headers
    );

    return { users: response.data.users, totalCount: response.data.totalCount };
  } catch (e) {
    throw new Error((e as Error).message);
  }
};
