import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TopMenuButtons from './TopMenuButtons';
import TopNotifications from './TopNotifications';

const TopPage: React.FC = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <TopNotifications />
        <TopMenuButtons />
      </Stack>
    </Box>
  );
};

export default TopPage;
