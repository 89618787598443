import { Dispatch, SetStateAction, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import {
  AnswerMetadataColumn,
  AnswersTableHeader,
  FilterCondition,
  SortCondition
} from '../../interface/AnswersTable';
import HeaderSortButtons from './HeaderSortButtons';
import { IconButton, SxProps, Theme } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import FilterCheckModal from './FilterCheckModal';
import { ExistingQuestion } from '../../interface/Question';
import { createNeatFilterConditions } from '../../common/filter';
import { ANSWER_METADATA_COLUMNS } from '../../common/answers-table';

type TableHeaderProps = {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  totalCount: number;
  headers: AnswersTableHeader[];
  sortConditions: SortCondition[];
  setSortConditions: Dispatch<SetStateAction<SortCondition[]>>;
  setFilterConditions: Dispatch<SetStateAction<FilterCondition[]>>;
  fetchAnswers: Function;
  filterConditions: FilterCondition[];
  questions: ExistingQuestion[];
  isUnique: boolean;
  questionnaireId: number;
  limit: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
};

const TableHeader: React.FC<TableHeaderProps> = (props) => {
  const onSelectAllClick = props.onSelectAllClick;
  const numSelected: number = props.numSelected;
  const totalCount: number = props.totalCount;
  const headers: AnswersTableHeader[] = props.headers;
  const filterConditions: FilterCondition[] = props.filterConditions;
  const isUnique: boolean = props.isUnique;
  const questionnaireId: number = props.questionnaireId;
  const limit: number = props.limit;
  const page: number = props.page;

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [targetQuestionId, setTargetQuestionId] = useState<number>();

  const existFilterConditions = (targetQuestionId: number): boolean => {
    const targetFilterConditions: FilterCondition[] =
      createNeatFilterConditions(filterConditions).filter(
        (filterCondition: FilterCondition) =>
          filterCondition.filterTarget === targetQuestionId
      );
    return targetFilterConditions.length !== 0;
  };

  const sortableHeaderStyle: SxProps<Theme> = {
    ':hover': {
      '> .sort-buttons': {
        '> .sort-asc-button': { visibility: 'visible' },
        '> .sort-desc-button': { visibility: 'visible' }
      },
      '> .filter-button': { visibility: 'visible' }
    }
  };

  const handleClickTargetQuestion = (targetQuestionId: number) => {
    setIsFilterModalOpen(true);
    setTargetQuestionId(targetQuestionId);
  };

  return (
    <>
      <TableHead
        sx={{
          position: 'sticky',
          zIndex: 2,
          top: '0',
          backgroundColor: 'white'
        }}
      >
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < totalCount}
              checked={numSelected > 0 && numSelected <= totalCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all'
              }}
            />
          </TableCell>
          <TableCell sx={sortableHeaderStyle} className="table-header-cell">
            ID
            <HeaderSortButtons
              sortTarget={0}
              sortConditions={props.sortConditions}
              setSortConditions={props.setSortConditions}
              setPage={props.setPage}
              fetchAnswers={props.fetchAnswers}
            />
          </TableCell>
          {headers.map((header, index) => (
            <TableCell
              sx={sortableHeaderStyle}
              key={index}
              className="table-header-cell"
            >
              {`[Q${index + 1}] ${header.name}`}
              <IconButton
                sx={{
                  visibility: existFilterConditions(header.id)
                    ? 'visible'
                    : 'hidden'
                }}
                onClick={() => {
                  handleClickTargetQuestion(header.id);
                }}
                className={'filter-button'}
              >
                <ArrowDropDown />
              </IconButton>
              {header.items === undefined && (
                <HeaderSortButtons
                  sortTarget={header.id}
                  sortConditions={props.sortConditions}
                  setSortConditions={props.setSortConditions}
                  setPage={props.setPage}
                  fetchAnswers={props.fetchAnswers}
                />
              )}
            </TableCell>
          ))}
          {ANSWER_METADATA_COLUMNS.map(
            (column: AnswerMetadataColumn, index) => (
              <TableCell
                sx={sortableHeaderStyle}
                key={index}
                className="table-header-metadata-cell"
              >
                {column.name}
                <HeaderSortButtons
                  sortTarget={column.sortTarget}
                  sortConditions={props.sortConditions}
                  setSortConditions={props.setSortConditions}
                  setPage={props.setPage}
                  fetchAnswers={props.fetchAnswers}
                />
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
      <FilterCheckModal
        headers={headers}
        isOpen={isFilterModalOpen}
        filterConditions={props.filterConditions}
        sortConditions={props.sortConditions}
        targetQuestionId={targetQuestionId}
        questions={props.questions}
        handleClose={() => {
          setTargetQuestionId(undefined);
          setIsFilterModalOpen(false);
        }}
        setFilterConditions={props.setFilterConditions}
        fetchAnswers={props.fetchAnswers}
        isUnique={isUnique}
        questionnaireId={questionnaireId}
        limit={limit}
        page={page}
        setPage={props.setPage}
      />
    </>
  );
};

export default TableHeader;
