import axios, { AxiosRequestConfig } from 'axios';
import { eventHeaders } from '../../common/auth';
import { API_BASE_URL } from '../constant';

export const postImprovementRequest = async (
  roles: string[],
  voice: string
) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/user-voice`,
    { roles, voice },
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }

  return response.status;
};
