import { useState, ChangeEvent, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { QuestionItem } from '../../interface/Question';
import { Answer } from '../../interface/Answer';
import { useDispatch } from 'react-redux';
import {
  addAnswer,
  removeAnswer,
  updateDescriptionAnswer
} from '../../redux/slice/AnswerSlice';
import { handleSelectableTextAnswerChange } from '../../common/answer';
import { getSentenceColor } from '../../common/color';
import { calculateTextFieldWidthByLabelLength } from '../../common';
import { useSelector } from '../../redux/store';

type CheckFormProps = {
  answers: Answer[];
  items: QuestionItem[];
  questionId: number;
  disabled: boolean;
  required?: boolean;
};

type CheckTextAnswer = { itemId: number; textAnswer: string };

const CheckForm: React.FC<CheckFormProps> = (props) => {
  const items: QuestionItem[] = props.items;
  const questionId: number = props.questionId;
  const required: boolean =
    props.required !== undefined ? props.required : false;
  const answers: Answer[] = props.answers;

  const [textAnswers, setTextAnswers] = useState<CheckTextAnswer[]>(
    items
      .filter((item: QuestionItem) => item.isDescription)
      .map((item: QuestionItem) => ({ itemId: item.id, textAnswer: '' }))
  );
  const isAnswersSet: boolean = useSelector(
    (state) => state.answer.isAnswersSet
  );

  const dispatch = useDispatch();
  const updateAnswer = (itemId: number, textAnswer: string) =>
    dispatch(updateDescriptionAnswer({ questionId, itemId, textAnswer }));
  const removeCheckAnswer = (itemId: number) =>
    dispatch(removeAnswer({ questionId, itemId }));
  const addCheckAnswer = (itemId: number, textAnswer?: string) =>
    dispatch(addAnswer({ questionId, itemId, textAnswer }));

  useEffect(() => {
    const initialTextAnswers: CheckTextAnswer[] = items
      .filter((item: QuestionItem) => item.isDescription)
      .map((item: QuestionItem) => {
        const targetAnswer: Answer | undefined = answers.find(
          (answer: Answer) => answer.itemId === item.id
        );
        const textAnswer: string =
          targetAnswer && targetAnswer.textAnswer
            ? targetAnswer.textAnswer
            : '';

        return { itemId: item.id, textAnswer };
      });
    setTextAnswers(initialTextAnswers);
  }, [isAnswersSet]);

  const handleCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked: boolean = event.target.checked;
    const itemId: number = Number(event.target.name);
    const isDescription: boolean = items.filter(
      (item: QuestionItem) => item.id === itemId
    )[0].isDescription;
    const textAnswer: string | undefined = textAnswers.find(
      (checkTextAnswer: CheckTextAnswer) => checkTextAnswer.itemId === itemId
    )?.textAnswer;

    // 記述式ではなく、チェックが入った場合
    if (isChecked && !isDescription) {
      addCheckAnswer(itemId);
      // 記述式であり、チェックが入った場合
    } else if (
      isChecked &&
      isDescription &&
      textAnswer !== '' &&
      textAnswer !== undefined
    ) {
      addCheckAnswer(itemId, textAnswer);
    } else {
      removeCheckAnswer(itemId);
    }
  };

  const isError = answers.length === 0 && required;
  const checkboxStyle = { color: getSentenceColor(isError) };

  return (
    <FormControl
      required={required}
      error={isError}
      component="fieldset"
      sx={{ m: 3 }}
      variant="standard"
    >
      <FormGroup>
        {items.map((item: QuestionItem) => {
          if (item.isDescription) {
            const textAnswer: string = textAnswers.find(
              (checkTextAnswer: CheckTextAnswer) =>
                checkTextAnswer.itemId === item.id
            )!.textAnswer;
            const targetAnswer: Answer | undefined = answers.find(
              (answer: Answer) => answer.itemId === item.id
            );
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckChange}
                    name={String(item.id)}
                    checked={
                      targetAnswer !== undefined &&
                      targetAnswer.itemId === item.id &&
                      textAnswer !== ''
                    }
                    sx={checkboxStyle}
                    disabled={props.disabled}
                  />
                }
                label={
                  <TextField
                    sx={{
                      width: calculateTextFieldWidthByLabelLength(item.name),
                      minWidth: '195px'
                    }}
                    variant="outlined"
                    value={textAnswer}
                    name={String(item.id)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const updatedTextAnswers: CheckTextAnswer[] =
                        textAnswers.map((checkTextAnswer: CheckTextAnswer) => {
                          if (checkTextAnswer.itemId === item.id)
                            return {
                              ...checkTextAnswer,
                              textAnswer: event.target.value
                            };

                          return checkTextAnswer;
                        });
                      setTextAnswers(updatedTextAnswers);

                      handleSelectableTextAnswerChange(
                        updateAnswer,
                        removeCheckAnswer
                      )(event);
                    }}
                    label={item.name}
                    disabled={props.disabled}
                  />
                }
                key={item.id}
              />
            );
          }

          return (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheckChange}
                  name={String(item.id)}
                  checked={
                    answers.filter(
                      (answer: Answer) =>
                        answer.questionId === questionId &&
                        answer.itemId === item.id
                    ).length === 1
                  }
                  sx={checkboxStyle}
                  disabled={props.disabled}
                />
              }
              label={item.name}
              key={item.id}
            />
          );
        })}
      </FormGroup>
      {isError && !props.disabled ? (
        <FormHelperText>1つ以上選択してください</FormHelperText>
      ) : (
        <></>
      )}
    </FormControl>
  );
};

export default CheckForm;
