import { useEffect, useRef, useState } from 'react';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';
import { EditingQuestion, EditingQuestionItem } from '../../interface/Question';
import OptionImportModal from './OptionImportModal';
import {
  isItemDeleted,
  isEmptyItem,
  isQuestionWithItem
} from '../../common/manageQuestion';
import { useDispatch } from 'react-redux';
import {
  addQuestionItem,
  moveQuestionItem
} from '../../redux/slice/QuestionnaireSlice';
import { useSelector } from '../../redux/store';
import { ERROR_COLOR } from '../../common/color';
import ItemAdditionTextField from './ItemAdditionTextField';
import SortableQuestionItem from './SortableQuestionItem';
import UnsortableQuestionItem from './UnsortableQuestionItem';

type ItemAreaProps = {
  items: EditingQuestionItem[];
};

const ItemArea: React.FC<ItemAreaProps> = (props) => {
  const dispatch = useDispatch();

  const items: EditingQuestionItem[] = props.items;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editableIndex, setEditableIndex] = useState<number>(-1);

  const [acceleration, setAcceleration] = useState<number>(900);
  const scrollContainerRef = useRef<HTMLElement | null>(null);

  const question: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );

  const handleCloseModal = () => setIsModalOpen(false);

  const handleAddQuestionItem = (newItemNames: string[]) =>
    dispatch(addQuestionItem(newItemNames));

  const existEmptyItem: boolean =
    isQuestionWithItem(question) && question.items!.some(isEmptyItem);

  const existQuestionsWithAllItemsDeleted: boolean =
    isQuestionWithItem(question) && question.items!.every(isItemDeleted);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over == null || active.id === over.id) {
      return;
    }
    dispatch(
      moveQuestionItem({
        activeId: Number(active.id),
        overId: Number(over!.id)
      })
    );
  };

  useEffect(() => {
    const elem = scrollContainerRef.current;
    const observer = new ResizeObserver(() => {
      if (elem) {
        elem.offsetHeight > 600 ? setAcceleration(1500) : setAcceleration(900);
      }
    });

    if (elem) {
      observer.observe(elem);
    }

    return () => {
      if (elem) {
        observer.unobserve(elem);
      }
    };
  }, [setAcceleration]);

  return (
    <>
      <Stack sx={{ marginTop: '0.5em' }} spacing={2} direction="row">
        <Typography
          gutterBottom
          variant="body1"
          component="div"
          sx={{ marginTop: '0.5em' }}
        >
          選択肢
        </Typography>
        <Button variant="contained" onClick={() => setIsModalOpen(true)}>
          選択肢一括追加
        </Button>
      </Stack>
      {existQuestionsWithAllItemsDeleted && (
        <FormHelperText sx={{ color: ERROR_COLOR }}>
          1つ以上選択肢を作成してください
        </FormHelperText>
      )}
      {!existQuestionsWithAllItemsDeleted && existEmptyItem && (
        <FormHelperText sx={{ color: ERROR_COLOR }}>
          選択肢名を入力してください
        </FormHelperText>
      )}
      <OptionImportModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        handleAddNewOptions={(newOptions: string[]) => {
          handleAddQuestionItem(newOptions);
          setIsModalOpen(false);
        }}
      />
      <FormGroup
        sx={{ maxWidth: '50%', marginTop: '1em' }}
        ref={scrollContainerRef}
      >
        <Stack spacing={1}>
          <DndContext
            collisionDetection={closestCenter}
            onDragStart={() => setEditableIndex(-1)}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
            autoScroll={{
              acceleration: acceleration
            }}
          >
            <SortableContext items={items}>
              {items.map((item: EditingQuestionItem, i: number) =>
                'isDeleted' in item && item.isDeleted ? (
                  <UnsortableQuestionItem
                    key={i}
                    index={i}
                    item={item}
                    editableIndex={editableIndex}
                    existEmptyItem={existEmptyItem}
                    existQuestionsWithAllItemsDeleted={
                      existQuestionsWithAllItemsDeleted
                    }
                    setEditableIndex={setEditableIndex}
                  />
                ) : (
                  <SortableQuestionItem
                    key={i}
                    index={i}
                    item={item}
                    editableIndex={editableIndex}
                    existEmptyItem={existEmptyItem}
                    existQuestionsWithAllItemsDeleted={
                      existQuestionsWithAllItemsDeleted
                    }
                    setEditableIndex={setEditableIndex}
                  />
                )
              )}
            </SortableContext>
          </DndContext>
        </Stack>
        <ItemAdditionTextField handleAddQuestionItem={handleAddQuestionItem} />
      </FormGroup>
    </>
  );
};

export default ItemArea;
