import { ChangeEvent, MouseEventHandler } from 'react';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import { ExistingQuestion } from '../../interface/Question';
import { FilterCondition } from '../../interface/AnswersTable';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import { CONDITIONS, isInvalidNumberCondition } from '../../common/filter';

type ConditionFormsProps = {
  filterCondition: FilterCondition;
  questions: ExistingQuestion[];
  changeFilterCondition: (event: SelectChangeEvent<any>) => void;
  changeFilterValue: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  removeFilter: MouseEventHandler<HTMLButtonElement>;
};

const ConditionForms: React.FC<ConditionFormsProps> = (props) => {
  const isNumberQuestion = (filterTarget: number): boolean => {
    if (filterTarget === 0) return false;
    const targetQuestion: ExistingQuestion = props.questions.find(
      (question: ExistingQuestion) => question.id === filterTarget
    )!;
    return targetQuestion.type === 'number';
  };
  const isFilterTargetNumberQuestion: boolean = isNumberQuestion(
    props.filterCondition.filterTarget
  );

  return (
    <Stack spacing={2} direction="row" sx={{ marginTop: '1em' }}>
      <FormControl sx={{ width: '35%' }}>
        <InputLabel>条件</InputLabel>
        <Select
          value={props.filterCondition.condition}
          label="条件"
          onChange={props.changeFilterCondition}
          aria-label="filter-condition"
        >
          {CONDITIONS.filter(
            (condition) => condition.condition !== 'strictEqual'
          ).map((condition) => (
            <MenuItem
              key={condition.condition}
              value={condition.condition}
              disabled={condition.isForNumber && !isFilterTargetNumberQuestion}
            >
              {condition.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="値"
        value={props.filterCondition.value}
        helperText={
          isInvalidNumberCondition(props.filterCondition)
            ? '数値のみ入力可能です。'
            : ''
        }
        error={isInvalidNumberCondition(props.filterCondition)}
        onChange={props.changeFilterValue}
        sx={{ width: '30%' }}
        aria-label="filter-value"
      />
      <ButtonWithToolTip
        title="条件削除"
        onClick={props.removeFilter}
        icon={<CancelIcon />}
        className="filter-remove"
      />
    </Stack>
  );
};

export default ConditionForms;
