import {
  MouseEvent,
  ChangeEvent,
  useState,
  Fragment,
  useRef,
  useEffect
} from 'react';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis
} from '@dnd-kit/modifiers';
import {
  DndContext,
  MouseSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  defaultDropAnimationSideEffects,
  UniqueIdentifier,
  MeasuringStrategy,
  pointerWithin
} from '@dnd-kit/core';
import {
  EditingQuestion,
  QuestionGroup,
  EditingQuestionOrGroup
} from '../../interface/Question';
import AddQuestionButton from './AddQuestionButton';
import InheritanceForm from './InheritanceForm';
import { Inheritance } from '../../interface/Inheritance';
import { useSelector } from '../../redux/store';
import {
  addQuestionIntoGroup,
  changeFocusingIndex,
  changeIsPublic,
  changeOrder,
  changeOrderWithInnerQuestion,
  changeQuestionnaireName,
  extractQuestionFromGroup,
  manageIsOpen
} from '../../redux/slice/QuestionnaireSlice';
import SaveButton from './SaveButton';
import OverlaySortableSource from './OverlaySortableSource';
import AddQuestionAndGroupButton from './AddQuestionAndGroupButton';
import {
  getDisplayIndex,
  getGroupedIndex,
  getQuestionIndexFromUniqueIdentifier,
  groupQuestions,
  isSameGroup
} from '../../common/manageQuestion';
import QuestionAccordion from './QuestionAccordion';
import QuestionGroupAccordion from './QuestionGroupAccordion';
import OverlaySortableAccordion from './OverlaySortableAccordion';
import CancelButton from './CancelButton';

type EditableQuestionnaireProps = {
  save: Function;
  canSave?: boolean;
};

const splitDndId = (uniqueIdentifier: UniqueIdentifier): string[] =>
  String(uniqueIdentifier).split('_');

const isTooltipButton = (element: HTMLElement, ariaLabel: string): boolean => {
  return (
    element.ariaLabel === ariaLabel ||
    element.parentElement!.ariaLabel === ariaLabel ||
    element.parentElement!.parentElement!.ariaLabel === ariaLabel
  );
};

const isNamedButton = (
  element: HTMLElement,
  ariaLabel: string,
  className: string
): boolean => {
  return (
    isTooltipButton(element, ariaLabel) &&
    (element.classList.contains(className) ||
      element.parentElement!.classList.contains(className) ||
      element.parentElement!.parentElement!.classList.contains(className))
  );
};

const EditableQuestionnaire: React.FC<EditableQuestionnaireProps> = (props) => {
  const save: Function = props.save;

  const [activeId, setActiveId] = useState<string | null>(null);

  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );

  const questionnaireName: string = useSelector(
    (state) => state.questionnaire.name
  );
  const inheritance: Inheritance = useSelector(
    (state) => state.questionnaire.inheritance
  );
  const isPublic: boolean = useSelector(
    (state) => state.questionnaire.isPublic
  );
  const groups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.groups
  );
  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );

  const [acceleration, setAcceleration] = useState<number>(900);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [toggleTargetQuestions, setToggleTargetQuestions] = useState<
    { id: number; isOpen: boolean }[]
  >([]);

  const toggleTargetQuestionsState: { id: number; isOpen: boolean }[] =
    questions.map((question: EditingQuestion) => {
      return {
        id: question.id,
        isOpen: question.isOpen
      };
    });

  const editingId: number | undefined =
    editingIndex === -1 ? undefined : questions[editingIndex].id;

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } })
  );
  const dispatch = useDispatch();

  // 質問のアコーディオンの開閉をコントロールする
  // 質問グループは開いたママとする
  const toggleQuestions = (isDragStart: boolean) => {
    // ドラッグ開始前の開閉状態を保持する
    setToggleTargetQuestions(toggleTargetQuestionsState);
    // ドラッグ開始時：すべて閉じる
    if (isDragStart) {
      toggleTargetQuestionsState.map(
        (targetQuestion: { id: number; isOpen: boolean }) =>
          dispatch(
            manageIsOpen({
              id: targetQuestion.id,
              isQuestion: true,
              isOpen: false
            })
          )
      );
      return;
    }
    // ドラッグ終了時：ドラッグ前の開閉状態に戻す
    toggleTargetQuestions.map(
      (targetQuestion: { id: number; isOpen: boolean }) =>
        dispatch(
          manageIsOpen({
            id: targetQuestion.id,
            isQuestion: true,
            isOpen: targetQuestion.isOpen
          })
        )
    );
  };

  const handleQuestionnaireName = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(changeQuestionnaireName(event.target.value as string));
  const onClickCard = (index: number) => (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      isTooltipButton(target, '上へ') ||
      isTooltipButton(target, '下へ') ||
      isNamedButton(target, '削除', 'delete-question-button') ||
      isNamedButton(target, '復元', 'restore-question-button')
    )
      return;
    return dispatch(changeFocusingIndex(index));
  };
  const onChangeIsPublicButton = () => dispatch(changeIsPublic(!isPublic));
  const violateCharacterCountLimit: boolean = questionnaireName.length > 200;
  const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
    questions,
    groups
  );

  const active: EditingQuestionOrGroup | undefined =
    activeId === null
      ? undefined
      : activeId.startsWith('innerQuestion_')
      ? questions[getQuestionIndexFromUniqueIdentifier(activeId)]
      : groupedQuestions[getGroupedIndex(activeId)];

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveId(null);
    // 質問のアコーディオンの開閉をドラッグ前の状態に戻す
    toggleQuestions(false);

    if (over === null) return;

    const splitActiveId: string[] = splitDndId(active.id);
    const splitOverId: string[] = splitDndId(over.id);
    if (
      active.id === over.id ||
      (splitActiveId[0] !== 'question' &&
        splitActiveId[0] !== 'innerQuestion' &&
        splitOverId[0] === 'dropGroup') ||
      (splitActiveId[0] === 'group' && splitOverId[0] === 'innerQuestion') ||
      (splitActiveId[0] === 'innerQuestion' &&
        splitOverId[0] === 'dropGroup' &&
        isSameGroup(
          questions,
          Number(splitActiveId[1]),
          Number(splitOverId[1])
        ))
    )
      return;

    if (
      String(over.id).startsWith('innerQuestion_') &&
      !String(active.id).startsWith('group_')
    ) {
      return dispatch(
        changeOrderWithInnerQuestion({
          activeId: String(active.id),
          overId: String(over.id)
        })
      );
    }

    if (
      String(active.id).startsWith('innerQuestion_') &&
      !String(over.id).startsWith('dropGroup_')
    ) {
      return dispatch(
        extractQuestionFromGroup({
          activeId: String(active.id),
          overId: String(over.id)
        })
      );
    }

    const isDropGroup: boolean = String(over.id).split('_')[0] === 'dropGroup';
    const overId: number = Number(String(over.id).split('_')[1]);
    if (isDropGroup)
      return dispatch(
        addQuestionIntoGroup({
          questionId:
            questions[getQuestionIndexFromUniqueIdentifier(active.id)].id,
          groupId: overId
        })
      );

    return dispatch(
      changeOrder({
        activeId: String(active.id),
        overId: String(over.id)
      })
    );
  };

  const existQuestionnaireName: boolean = questionnaireName !== '';

  // グループと質問が混ざった状態の配列では質問のindexを取得できないので、質問IDから質問のindexを逆引きする
  const getQuestionIndex = (questionId: number) => {
    return questions.findIndex(
      (question: EditingQuestion) => question.id === questionId
    );
  };

  const getGroupIndex = (groupId: number) =>
    groupedQuestions
      .filter(
        (groupOrQuestion: EditingQuestionOrGroup) =>
          'groupName' in groupOrQuestion
      )
      .findIndex(
        (groupOrQuestion: EditingQuestionOrGroup) =>
          groupOrQuestion.groupId === groupId
      );

  const getQuestionIndexOffset = (index: number): number =>
    groupedQuestions
      .filter((_, i: number) => i < index)
      .flatMap((questionOrGroup: EditingQuestionOrGroup) => {
        if ('groupName' in questionOrGroup) return questionOrGroup.questions;

        return questionOrGroup;
      }).length;

  useEffect(() => {
    const elem = scrollContainerRef.current;
    const observer = new ResizeObserver(() => {
      if (elem) {
        elem.offsetHeight > 600 ? setAcceleration(1500) : setAcceleration(900);
      }
    });

    if (elem) {
      observer.observe(elem);
    }

    return () => {
      if (elem) {
        observer.unobserve(elem);
      }
    };
  }, [setAcceleration]);

  return (
    <>
      <CancelButton />
      <div>
        <TextField
          label="アンケート名"
          variant="standard"
          sx={{ margin: '0.5em', width: '30%' }}
          value={questionnaireName}
          onChange={handleQuestionnaireName}
          error={!existQuestionnaireName || violateCharacterCountLimit}
          helperText={
            !existQuestionnaireName
              ? 'アンケート名を入力してください'
              : violateCharacterCountLimit
              ? '文字数が長すぎます'
              : ''
          }
          id="questionnaire-name-input"
        />
      </div>
      <div>
        <FormControlLabel
          control={<Switch id="public-switch" />}
          label={
            <p>
              このアンケートの回答を全ユーザーに公開する
              <br />
              <strong>
                (オフにするとDoQの管理者とフォームの管理者以外は自分以外の回答を閲覧できなくなります。)
              </strong>
            </p>
          }
          checked={isPublic}
          onChange={onChangeIsPublicButton}
        />
      </div>
      <div>
        <InheritanceForm />
      </div>
      <AddQuestionAndGroupButton groupedIndex={0} />
      <div ref={scrollContainerRef}>
        <DndContext
          sensors={sensors}
          onDragStart={(event) => {
            // ドラッグ中のIDを保存する
            setActiveId(String(event.active.id));
            // ドラッグ注は質問のアコーディオンをすべて閉じる
            toggleQuestions(true);
          }}
          onDragEnd={handleDragEnd}
          collisionDetection={pointerWithin}
          modifiers={[restrictToVerticalAxis]}
          autoScroll={{
            acceleration: acceleration
          }}
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.Always
            }
          }}
        >
          <SortableContext
            items={questions}
            strategy={verticalListSortingStrategy}
          >
            {groupedQuestions.map(
              (questionOrGroup: EditingQuestionOrGroup, index: number) => {
                const subsequent: EditingQuestionOrGroup | undefined =
                  index === groupedQuestions.length - 1
                    ? undefined
                    : groupedQuestions[index + 1];
                const isBottom: boolean =
                  subsequent === undefined ||
                  ('question' in subsequent &&
                    'isDeleted' in subsequent &&
                    subsequent.isDeleted);
                return (
                  <Fragment key={index}>
                    {'groupName' in questionOrGroup ? (
                      <OverlaySortableAccordion
                        groupedQuestion={questionOrGroup}
                        groupIndex={getGroupIndex(questionOrGroup.groupId)}
                        questionIndexOffset={getQuestionIndexOffset(index)}
                        isBottom={isBottom}
                        groupedIndex={index}
                        editingId={editingId}
                      />
                    ) : (
                      <OverlaySortableAccordion
                        question={questionOrGroup}
                        index={getQuestionIndex(questionOrGroup.id)}
                        displayIndex={getDisplayIndex(
                          questions,
                          questionOrGroup
                        )}
                        isTop={index === 0}
                        isBottom={isBottom}
                        deletable={
                          !(inheritance.questionId === questionOrGroup.id)
                        }
                        onClick={onClickCard(questionOrGroup.id)}
                        existQuestionCondition={
                          questionOrGroup.questionConditions !== undefined &&
                          questionOrGroup.questionConditions.flat().length !== 0
                        }
                        groupedIndex={index}
                      />
                    )}
                    {!(
                      'isDeleted' in questionOrGroup &&
                      questionOrGroup.isDeleted
                    ) ? (
                      <AddQuestionAndGroupButton groupedIndex={index + 1} />
                    ) : (
                      <p></p>
                    )}
                  </Fragment>
                );
              }
            )}
          </SortableContext>
          <DragOverlay
            modifiers={[restrictToFirstScrollableAncestor]}
            dropAnimation={{
              sideEffects: defaultDropAnimationSideEffects({
                styles: {}
              })
            }}
          >
            {active && 'question' in active && (
              <OverlaySortableSource
                children={
                  <QuestionAccordion
                    question={active}
                    index={getQuestionIndexFromUniqueIdentifier(activeId!)}
                    displayIndex={getDisplayIndex(questions, active)}
                    isTop={true}
                    isBottom={true}
                    deletable={!(inheritance.questionId === active.id)}
                    onClick={() => {}}
                    existQuestionCondition={
                      active.questionConditions !== undefined &&
                      active.questionConditions.length > 0
                    }
                  />
                }
              />
            )}
            {active && 'groupName' in active && (
              <OverlaySortableSource
                children={
                  <QuestionGroupAccordion
                    groupedQuestion={active}
                    groupIndex={getGroupIndex(active.groupId)}
                    questionIndexOffset={0}
                    groupedIndex={groupedQuestions.findIndex(
                      (groupOrQuestion: EditingQuestionOrGroup) =>
                        'groupName' in groupOrQuestion &&
                        groupOrQuestion.groupId === active.groupId
                    )}
                    isBottom={true}
                    editingId={editingId}
                  />
                }
              />
            )}
          </DragOverlay>
        </DndContext>
      </div>
      <AddQuestionButton />
      <SaveButton save={save} canSave={props.canSave} />
    </>
  );
};

export default EditableQuestionnaire;
