import {
  AnswerPerQuestion,
  AnswersTableMetaData,
  AnswersTableHeader,
  AnswerMetadataColumn
} from '../interface/AnswersTable';

export const ANSWER_METADATA_COLUMNS: AnswerMetadataColumn[] = [
  { sortTarget: -1, name: '回答者メールアドレス' },
  { sortTarget: -2, name: '回答者氏名' },
  { sortTarget: -3, name: '回答日' },
  { sortTarget: -4, name: '更新者メールアドレス' },
  { sortTarget: -5, name: '更新者氏名' },
  { sortTarget: -6, name: '更新日' }
];

export const updateAnswer = (
  answers: AnswersTableMetaData,
  headers: AnswersTableHeader[]
): AnswerPerQuestion[] => {
  const answersWithItemId: AnswerPerQuestion[] = answers.answer.filter(
    (data) => 'itemId' in data
  );

  const sameIds: number[] = Array.from(
    new Set(
      answersWithItemId.map((answer) => {
        return answer.id;
      })
    )
  );

  const uniqueIds: number[] = Array.from(
    new Set(
      answers.answer.map((answer) => {
        return answer.id;
      })
    )
  );

  const combinedAnswers: AnswerPerQuestion[] = sameIds.map((id) => {
    return {
      id: id,
      itemName: answersWithItemId
        .filter((answer) => answer.id === id)
        .map((answer) => {
          return answer.textAnswer ? answer.textAnswer : answer.itemName;
        })
        .join(',')
    };
  });

  const formedAnswer: AnswerPerQuestion[] = uniqueIds.map((id) => {
    if (sameIds.indexOf(id) === -1) {
      const index = answers.answer.findIndex((answer) => answer.id === id);
      return {
        id: id,
        itemName: answers.answer[index].textAnswer
          ? answers.answer[index].textAnswer
          : answers.answer[index].itemName
      };
    }
    const index = combinedAnswers.findIndex((answer) => answer.id === id);
    return {
      id: id,
      itemName: combinedAnswers[index].itemName
    };
  });

  const answersWithHeadersId: AnswerPerQuestion[] = headers.map((header) => {
    const index = formedAnswer.findIndex((answer) => answer.id === header.id);
    if (index === -1) {
      return {
        id: header.id,
        itemName: ''
      };
    }
    return {
      id: header.id,
      itemName: formedAnswer[index].itemName
    };
  });
  return answersWithHeadersId;
};
