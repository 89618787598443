export const isNumber = (str: string): boolean => {
  const isRealNumber: boolean =
    str.match(/^[-]?\d+(?:\.\d+)?$/) === null ? false : true;

  const isOctalNumber =
    str.startsWith('0') && str !== '0' && !str.includes('.');

  return isRealNumber && !isOctalNumber;
};

// labelの文字数に応じたTextFieldの幅を計算
export const calculateTextFieldWidthByLabelLength = (
  labelText: string
): string => {
  const FULL_CHAR_WIDTH: number = 14; // 目安のピクセル数
  const HALF_CHAR_WIDTH: number = 8; // 目安のピクセル数
  const labelTextWidth: number = labelText
    .split('')
    .reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue.match(/[ -~]/) ? HALF_CHAR_WIDTH : FULL_CHAR_WIDTH),
      0
    );
  return `${labelTextWidth}px`;
};
