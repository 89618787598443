import { Dispatch, SetStateAction, useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Inheritance } from '../../interface/Inheritance';
import { useSelector } from '../../redux/store';
import ReferenceToAnswerModal from './ReferenceToAnswerModal';
import { useDispatch } from 'react-redux';
import { overwriteAnswers } from '../../redux/slice/AnswerSlice';
import { Answer } from '../../interface/Answer';
import { fetchAnswersByMetadataId } from '../../api';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

type AnswerQuotationButtonProps = {
  isPublic: boolean;
  setDisabledKeyQuestionChange: Dispatch<SetStateAction<boolean>>;
};

const AnswerQuotationButton: React.FC<AnswerQuotationButtonProps> = (props) => {
  const { isPublic, setDisabledKeyQuestionChange } = props;

  const inheritance: Inheritance | undefined = useSelector(
    (state) => state.answer.inheritance
  );

  const answers: Answer[] = useSelector((state) => state.answer.answers);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const setAnswers = (answers: Answer[]) => dispatch(overwriteAnswers(answers));

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    inheritance !== undefined &&
    inheritance.questionId !== undefined &&
    !inheritance.isSameUser &&
    isPublic && (
      <>
        <Tooltip
          title="すでに回答済みの内容を引用する場合はこちら"
          placement="right-start"
        >
          <Button
            className="answer-quotation-button"
            variant="outlined"
            sx={{ mt: '1em' }}
            size="large"
            startIcon={<QuestionAnswerIcon />}
            onClick={() => setIsModalOpen(true)}
          >
            他の回答を引用する
          </Button>
        </Tooltip>
        <ReferenceToAnswerModal
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          reflectAnswers={(metadataId: number) => async () => {
            handleCloseModal();
            const targetAnswers: Answer[] = await fetchAnswersByMetadataId(
              metadataId
            );
            const currentKeyQuestionAnswer: Answer | undefined = answers.find(
              (answer) => answer.questionId === inheritance.questionId!
            );
            const newAnswers: Answer[] =
              currentKeyQuestionAnswer === undefined
                ? targetAnswers.filter(
                    (answer: Answer) =>
                      answer.questionId !== inheritance.questionId!
                  )
                : targetAnswers.map((answer: Answer) => {
                    if (answer.questionId === inheritance.questionId) {
                      return currentKeyQuestionAnswer;
                    } else {
                      return answer;
                    }
                  });
            setAnswers(newAnswers);
            setDisabledKeyQuestionChange(true);
          }}
          quit={handleCloseModal}
        />
      </>
    )
  );
};

export default AnswerQuotationButton;
