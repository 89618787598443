import { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

type Menu = {
  id: string;
  path: string;
  name: string;
  isOnlyAdmin?: boolean;
  isFolded?: boolean;
  isInternalPath: boolean;
};

const FunctionMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [role, setRole] = useState<string>('一般');
  const navigate = useNavigate();
  const location = useLocation();

  // フォーム編集、フォーム新規作成・複製ページでメニューボタン押下後、確認ダイヤログを出す
  const handleTransitionConfirmation = (isInternalPath: boolean): boolean => {
    if (
      isInternalPath &&
      (location.pathname.indexOf('form-edit') > 0 ||
        location.pathname.indexOf('form-management/new') > 0)
    ) {
      const isDiscardedOK = window.confirm(
        '保存されていないデータは削除されますが、よろしいですか？'
      );
      return isDiscardedOK;
    }

    return true;
  };

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user.attributes['custom:role'] !== undefined) {
        setRole(user.attributes['custom:role']);
      }
    })();
  }, []);

  const handleDrawerOpen = (status: boolean) => () => {
    setIsOpen(status);
  };

  const onClickMenuButton =
    (status: boolean, path: string, isInternalPath: boolean) => () => {
      handleDrawerOpen(status)();
      handleCloseNestedMenu();
      const isTransitionConfirmation =
        handleTransitionConfirmation(isInternalPath);

      if (!isTransitionConfirmation) {
        return;
      }

      if (isInternalPath) {
        navigate(path);
        return;
      }
      window.open(path, '_blank');
    };

  const openNestedMenu =
    (name: string) => (event: React.MouseEvent<HTMLElement>) => {
      setSelected(name);
      setAnchorEl(event.currentTarget);
    };

  const handleCloseNestedMenu = () => {
    setAnchorEl(null);
    setSelected('');
  };

  const menuList: Menu[] = [
    {
      id: 'top-menu-button',
      name: 'トップ',
      path: '/',
      isFolded: false,
      isOnlyAdmin: false,
      isInternalPath: true
    },
    {
      id: 'form-management-menu-button',
      name: 'フォーム作成／管理',
      path: '/form-management',
      isFolded: false,
      isOnlyAdmin: false,
      isInternalPath: true
    },
    {
      id: 'form-answers-table-menu-button',
      name: 'フォーム回答一覧',
      path: '/form-answers-table',
      isFolded: false,
      isOnlyAdmin: false,
      isInternalPath: true
    },
    {
      id: 'file-io-menu-button',
      name: '回答一括アップロード',
      path: '/csv-upload',
      isFolded: false,
      isOnlyAdmin: false,
      isInternalPath: true
    },
    {
      id: 'user-management-menu-button',
      name: 'ユーザ管理',
      path: '/user-management',
      isFolded: false,
      isOnlyAdmin: false,
      isInternalPath: true
    },
    {
      id: 'notification-menu-button',
      name: 'お知らせ管理',
      path: '/',
      isFolded: true,
      isOnlyAdmin: true,
      isInternalPath: true
    },
    {
      id: 'manual-menu-button',
      name: 'マニュアル',
      path: 'https://docomo-common.atlassian.net/wiki/spaces/PDKNOWHOW/pages/171335001506/DoQ',
      isFolded: false,
      isOnlyAdmin: false,
      isInternalPath: false
    }
  ];

  const nestedMenu: { [name: string]: Menu[] } = {
    お知らせ管理: [
      {
        id: 'notification-register-menu-button',
        name: 'お知らせ登録',
        path: '/notification-register',
        isInternalPath: true
      },
      {
        id: 'notification-edit-menu-button',
        name: 'お知らせ編集',
        path: '/notification-edit',
        isInternalPath: true
      }
    ],
    '': []
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen(true)}
        edge="start"
        sx={{ mr: 2, ...(isOpen && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={isOpen} onClose={handleDrawerOpen(false)}>
        <List>
          {menuList
            .filter(
              (menu: Menu) =>
                (role !== '管理者' && !menu.isOnlyAdmin) || role === '管理者'
            )
            .map((menu: Menu) => (
              <ListItemButton
                id={menu.id}
                divider
                onClick={
                  menu.isFolded!
                    ? openNestedMenu(menu.name)
                    : onClickMenuButton(false, menu.path, menu.isInternalPath)
                }
                key={menu.name}
              >
                <ListItemText primary={menu.name} />
              </ListItemButton>
            ))}
        </List>
      </Drawer>
      {Boolean(anchorEl) ? (
        <MuiMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseNestedMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {nestedMenu[selected].map((menu: Menu) => (
            <MenuItem
              id={menu.id}
              onClick={onClickMenuButton(false, menu.path, menu.isInternalPath)}
              key={menu.name}
            >
              {menu.name}
            </MenuItem>
          ))}
        </MuiMenu>
      ) : (
        <></>
      )}
    </>
  );
};

export default FunctionMenu;
