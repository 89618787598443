import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  QuestionResponse,
  ExistingQuestion,
  EditingQuestion,
  QuestionGroup
} from '../../interface/Question';
import {
  fetchQuestions,
  updateQuestionnaire,
  fetchQuestionnaires
} from '../../api';
import EditableQuestionnaire from './EditableQuestionnaire';
import {
  completelyExpandQuestionResponse,
  confirmInheritanceExistence,
  createInheritance
} from '../../common/manageQuestion';
import {
  EditedQuestionnaire,
  QuestionnaireGetResponse,
  QuestionnaireMetaData
} from '../../interface/Questionnaire';
import { Inheritance } from '../../interface/Inheritance';
import { useSelector } from '../../redux/store';
import {
  clearState,
  initialize,
  replaceEditingQuestion
} from '../../redux/slice/QuestionnaireSlice';
import {
  createEditedQuestionnaire,
  extractGroups
} from '../../common/formatQuestionForEdit';
import { mergeQuestionWithEditing } from '../../common/manageQuestion';

const FormEditPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prevLocation = useRef(location);
  const questionnaireId: number = Number(location.pathname.split('/')[2]);
  const initialQuestionnaireNameFromPreviousPage: string =
    location.state === null ? '' : (location.state.questionnaireName as string);
  const [questionnaires, setQuestionnaires] = useState<QuestionnaireMetaData[]>(
    [
      {
        id: 0,
        name: '',
        userId: '',
        createdDate: '',
        updatedDate: '',
        isDeleted: false,
        hash: ''
      }
    ]
  );

  // ブラウザのリロードによって読み込まれているか判定する
  const getIsReloadState = (): boolean => {
    const navigationEntries: any =
      window.performance.getEntriesByType('navigation')[0];
    const navigationType = navigationEntries.type;
    if (navigationType) return navigationType === 'reload';

    return false;
  };

  // リロードされた場合アンケート名を取得すためにAPIを実行する
  useEffect(() => {
    const isReload = getIsReloadState();
    if (isReload) {
      (async () => {
        let offset: number = 0;
        while (1) {
          const response: QuestionnaireGetResponse = await fetchQuestionnaires(
            100,
            offset
          );
          offset += response.questionnaires.length;
          setQuestionnaires([...questionnaires, ...response.questionnaires]);
          if (offset >= response.totalCount) break;
        }
      })();
    }

    // ページ離脱・リロード・ブラウザバック時の制御
    window.addEventListener('beforeunload', handleBeforeUnload, false);
    window.history.pushState(null, '', null);
    window.addEventListener('popstate', handlePopState, false);

    // 他のページに影響しないようクリアする
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const initialQuestions: ExistingQuestion[] = useSelector(
    (state) => state.questionnaire.initialQuestions
  );
  const initialGroups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.initialGroups
  );
  const inheritance: Inheritance = useSelector(
    (state) => state.questionnaire.inheritance
  );
  const initialInheritance: Inheritance = useSelector(
    (state) => state.questionnaire.initialInheritance
  );
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const isPublic: boolean = useSelector(
    (state) => state.questionnaire.isPublic
  );
  const initialIsPublic: boolean = useSelector(
    (state) => state.questionnaire.initialIsPublic
  );
  const groups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.groups
  );
  const isSaved: boolean = useSelector((state) => state.questionnaire.isSaved);
  const questionnaireName: string = useSelector(
    (state) => state.questionnaire.name
  );
  const initialQuestionnaireName: string = useSelector(
    (state) => state.questionnaire.initialQuestionnaireName
  );
  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const deleteTargetGroupIds: number[] = useSelector(
    (state) => state.questionnaire.deletedGroupIds
  );

  const isInheritance: boolean = confirmInheritanceExistence(
    questions,
    editingQuestion,
    editingIndex
  );

  const save = async () => {
    const saveTarget: EditingQuestion[] = mergeQuestionWithEditing(
      questions,
      editingIndex,
      editingQuestion
    );

    const inheritanceQuestionIndex: number = questions.findIndex(
      (question) => question.id === inheritance.questionId!
    );
    const updatedInheritance = isInheritance
      ? createInheritance(
          inheritance.isSameUser,
          inheritanceQuestionIndex,
          inheritanceQuestionIndex === -1
            ? undefined
            : questions[inheritanceQuestionIndex]
        )
      : undefined;

    const editedQuestionnaire: EditedQuestionnaire = createEditedQuestionnaire(
      initialQuestions,
      initialGroups,
      questionnaireId,
      questionnaireName,
      saveTarget,
      isPublic,
      updatedInheritance,
      groups,
      deleteTargetGroupIds
    );
    await updateQuestionnaire(editedQuestionnaire);
  };

  const initializeQuestionnaire = (fetched: {
    questions: ExistingQuestion[];
    isPublic: boolean;
    inheritance?: Inheritance;
    groups?: QuestionGroup[];
    name: string;
  }) => dispatch(initialize(fetched));

  const clear = () => dispatch(clearState());

  const replaceEditing = (question: EditingQuestion) =>
    dispatch(replaceEditingQuestion(question));

  const updateInitialStates = async (
    questionnaireId: number,
    questionnaireName: string,
    editingIndex?: number
  ) => {
    const response: QuestionResponse = await fetchQuestions(
      questionnaireId,
      true
    );
    const groups: QuestionGroup[] = extractGroups(response.questions);
    const questions: ExistingQuestion[] = completelyExpandQuestionResponse(
      response.questions
    );
    initializeQuestionnaire({
      questions,
      isPublic: response.isPublic,
      inheritance: response.inheritance,
      groups,
      name: questionnaireName
    });

    if (editingIndex !== undefined && editingIndex !== -1)
      replaceEditing(questions[editingIndex]);
  };

  const canSave = (): boolean => {
    const updatedQuestions: EditingQuestion[] = mergeQuestionWithEditing(
      questions,
      editingIndex,
      editingQuestion
    );
    return (
      !_.isEqual(updatedQuestions, initialQuestions) ||
      !_.isEqual(inheritance, initialInheritance) ||
      !_.isEqual(groups, initialGroups) ||
      !_.isEqual(questionnaireName, initialQuestionnaireName) ||
      isPublic !== initialIsPublic
    );
  };

  const canSaveRef = useRef<boolean>(false);
  canSaveRef.current = canSave();

  // ブラウザバックを検知後確認ダイヤログを出す
  const handlePopState = () => {
    if (
      location.pathname === prevLocation.current.pathname &&
      canSaveRef.current
    ) {
      const isDiscardedOK = window.confirm(
        '保存されていないデータは削除されますが、よろしいですか？'
      );
      if (isDiscardedOK) {
        // OKの場合、historyAPIで戻るを実行します。
        window.history.back();
      }
      // キャンセルの場合、 ダミー履歴を挿入して「戻る」を1回分吸収できる状態にする
      window.history.pushState(null, '', null);
    } else {
      window.history.back();
    }
  };

  // ブラウザのリロード・離脱を検知後確認ダイヤログを出す
  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (canSaveRef.current) {
      e.preventDefault();
      // 非推奨だが、ChromeではreturnValueを設定する必要がある
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      e.returnValue = '';
    }
  };

  useEffect(() => {
    clear();
    const isReload = getIsReloadState();

    if (initialQuestionnaireNameFromPreviousPage === '' && !isReload) {
      navigate('/');
    }

    const afterReloadGetQuestionnaireName: string = questionnaires.find(
      (questionnaire) => questionnaire.id === questionnaireId
    )?.name!;

    updateInitialStates(
      questionnaireId,
      isReload
        ? afterReloadGetQuestionnaireName!
        : initialQuestionnaireNameFromPreviousPage!
    );
  }, [questionnaireId, questionnaires]);

  useEffect(() => {
    if (!isSaved) return;

    setTimeout(() => {
      updateInitialStates(questionnaireId, questionnaireName, editingIndex);
    }, 1000);
  }, [isSaved]);

  return (
    <EditableQuestionnaire
      save={save}
      canSave={
        !_.isEqual(questions, initialQuestions) ||
        !_.isEqual(inheritance, initialInheritance) ||
        !_.isEqual(groups, initialGroups) ||
        !_.isEqual(questionnaireName, initialQuestionnaireName) ||
        isPublic !== initialIsPublic
      }
    />
  );
};

export default FormEditPage;
