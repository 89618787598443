import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import {
  QuestionnaireGetResponse,
  QuestionnaireMetaData
} from '../../interface/Questionnaire';
import { deleteQuestionnaire, fetchQuestionnaires } from '../../api';
import ConfirmModal from '../common/ConfirmModal';
import { useSnackbar } from '../../hooks/useSnackbar';
import Snackbar from '../common/Snackbar';

type TargetQuestionnaire = { hash: string; name: string };

const QuestionnairesTable: React.FC = () => {
  const navigate = useNavigate();
  const [metadata, setMetadata] = useState<QuestionnaireMetaData[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [openTips, setOpenTips] = useState<boolean[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [targetQuestionnaire, setTargetQuestionnaire] =
    useState<TargetQuestionnaire>({ hash: '', name: '' });
  const [
    isSnackbarOpen,
    severity,
    snackbarMessage,
    openSnackbar,
    closeSnackbar
  ] = useSnackbar('');

  const questionnairesTableHeaderStyle = {
    fontSize: 'large',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid silver'
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  const updateTable = async () => {
    const response: QuestionnaireGetResponse = await fetchQuestionnaires(
      limit,
      page * limit,
      false
    );

    setMetadata(response.questionnaires);
    setOpenTips(
      [...Array(response.questionnaires.length)].map((_, i: number) => false)
    );
    setTotalCount(response.totalCount);
  };

  useEffect(() => {
    updateTable();
  }, [page, limit]);

  const onClickCopy = (url: string, index: number) => async () => {
    const openState: boolean[] = [...Array(openTips.length)].map(
      (_, i: number) => i === index
    );
    setOpenTips(openState);
    await global.navigator.clipboard.writeText(url);
    setTimeout(() => {
      setOpenTips([...Array(openTips.length)].map((_, i: number) => false));
    }, 500);
  };

  const getUrl = (hash: string): string =>
    `${
      (window as any)._env_.REACT_APP_AWS_REDIRECT_SIGN_IN
    }/form-answer/${hash}`;

  const handleCloseModal = () => setIsModalOpen(false);

  const executeDelete =
    (hash: string, questionnaireName: string) => async () => {
      try {
        await deleteQuestionnaire(hash);
        openSnackbar('success', `${questionnaireName}の削除が完了しました。`);
        updateTable();
      } catch (e) {
        openSnackbar('error', `${questionnaireName}を削除できませんでした。`);
      } finally {
        setIsModalOpen(false);
      }
    };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, mt: '2%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={questionnairesTableHeaderStyle}>
                  <b>フォーム名</b>
                </TableCell>
                <TableCell sx={questionnairesTableHeaderStyle}>
                  <b>回答者向けURL（新規回答はこちらから行えます）</b>
                </TableCell>
                <TableCell sx={questionnairesTableHeaderStyle}></TableCell>
                <TableCell sx={questionnairesTableHeaderStyle}></TableCell>
                <TableCell sx={questionnairesTableHeaderStyle}></TableCell>
                <TableCell sx={questionnairesTableHeaderStyle}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metadata.map(
                (questionnaire: QuestionnaireMetaData, index: number) => (
                  <TableRow key={questionnaire.id}>
                    <TableCell align="left">{questionnaire.name}</TableCell>
                    <TableCell align="left">
                      <Link href={getUrl(questionnaire.hash)}>
                        {getUrl(questionnaire.hash)}
                      </Link>
                      {openTips[index] ? (
                        <Tooltip
                          arrow
                          open={openTips[index]}
                          disableHoverListener
                          title="コピーしました。"
                        >
                          <IconButton disabled>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <ButtonWithToolTip
                          title="コピー"
                          onClick={onClickCopy(
                            getUrl(questionnaire.hash),
                            index
                          )}
                          icon={<ContentCopyIcon />}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate(`/form-management/new/${questionnaire.id}`, {
                            state: {
                              questionnaireName: questionnaire.name
                            }
                          });
                        }}
                        aria-label="duplication"
                      >
                        複製
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate(`/form-authority/${questionnaire.hash}`);
                        }}
                      >
                        権限
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate(`/form-edit/${questionnaire.id}`, {
                            state: { questionnaireName: questionnaire.name }
                          });
                        }}
                        aria-label="edit"
                      >
                        編集
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          setIsModalOpen(true);
                          setTargetQuestionnaire({
                            hash: questionnaire.hash,
                            name: questionnaire.name
                          });
                        }}
                      >
                        削除
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmModal
        isOpen={isModalOpen}
        question={`${targetQuestionnaire.name}を削除してよろしいですか？`}
        handleClose={handleCloseModal}
        execute={executeDelete(
          targetQuestionnaire.hash,
          targetQuestionnaire.name
        )}
        quit={handleCloseModal}
      />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => closeSnackbar()}
        severity={severity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default QuestionnairesTable;
