import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ButtonWithToolTip from './ButtonWithToolTip';

type AuthorityManagerProps = {
  havingAuthorities: JSX.Element;
  notHavingAuthorities: JSX.Element;
  eliminateAuthorities: React.MouseEventHandler<HTMLButtonElement>;
  grantAuthorities: React.MouseEventHandler<HTMLButtonElement>;
  checkedHavingIds: number[] | string[];
  checkedNotHavingIds: number[] | string[];
  paginationButtons: JSX.Element;
};

const boxStyle = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#000',
  width: '100%',
  height: '15em',
  maxHeight: '15em',
  overflow: 'auto'
};

const AuthorityManager: React.FC<AuthorityManagerProps> = (props) => {
  return (
    <Stack spacing={2} direction="row">
      <Stack direction="column" sx={{ minWidth: '25%' }}>
        <Typography>フォーム権限未所有一覧</Typography>
        <Box
          sx={boxStyle}
          className="authority-div"
          id="not-having-options-box"
        >
          {props.notHavingAuthorities}
        </Box>
        {props.paginationButtons}
      </Stack>
      <Stack direction="column">
        <ButtonWithToolTip
          title="権限を付与"
          onClick={props.grantAuthorities}
          icon={<KeyboardArrowRightIcon />}
          sx={{ marginTop: '3em' }}
          disabled={props.checkedNotHavingIds.length === 0}
          className="grant-authorities-button"
        />
        <ButtonWithToolTip
          title="権限を削除"
          onClick={props.eliminateAuthorities}
          icon={<KeyboardArrowLeftIcon />}
          sx={{ marginTop: '1em' }}
          disabled={props.checkedHavingIds.length === 0}
          className="eliminate-authorities-button"
        />
      </Stack>
      <Stack direction="column" sx={{ minWidth: '25%' }}>
        <Typography>フォーム権限所有一覧</Typography>
        <Box sx={boxStyle} className="authority-div" id="having-options-box">
          {props.havingAuthorities}
        </Box>
      </Stack>
    </Stack>
  );
};

export default AuthorityManager;
