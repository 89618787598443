import axios, { AxiosRequestConfig } from 'axios';
import {
  QuestionnaireGetResponse,
  QuestionnaireMetaData,
  QuestionnaireByUserIdRequest
} from '../../interface/Questionnaire';
import { eventHeaders } from '../../common/auth';
import { API_BASE_URL } from '../constant';

export const fetchQuestionnaires = async (
  limit: number,
  offset: number,
  isAll?: boolean
): Promise<QuestionnaireGetResponse> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.get(`${API_BASE_URL}/questionnaire`, {
    params: { limit, offset, isAll },
    ...headers
  });

  return {
    questionnaires: response.data.questionnaires,
    totalCount: response.data.totalCount
  };
};

export const fetchQuestionnaireByHash = async (
  hash: string
): Promise<QuestionnaireMetaData> => {
  const headers: AxiosRequestConfig = await eventHeaders();

  const response = await axios.get(`${API_BASE_URL}/questionnaire/${hash}`, {
    ...headers
  });

  return response.data.questionnaire;
};

export const fetchQuestionnaireByUserId = async (
  userId: string,
  isAll?: boolean
): Promise<QuestionnaireMetaData[]> => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const requestBody: QuestionnaireByUserIdRequest = {
    userId: userId,
    isAll: isAll === undefined ? false : isAll
  };

  const response = await axios.post(
    `${API_BASE_URL}/questionnaire/user/`,
    requestBody,
    headers
  );

  return response.data.questionnaires;
};

export const deleteQuestionnaire = async (hash: string) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.delete(`${API_BASE_URL}/questionnaire/${hash}`, {
    ...headers
  });

  return response.data.deletedId;
};
