import {
  useEffect,
  useState,
  ChangeEvent,
  Dispatch,
  SetStateAction
} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { SelectChangeEvent } from '@mui/material/Select';
import AddCircle from '@mui/icons-material/AddCircle';
import ConditionForms from './ConditionForms';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import {
  ConditionOption,
  FilterCondition,
  SortCondition
} from '../../interface/AnswersTable';
import {
  EditingQuestionItem,
  ExistingQuestion
} from '../../interface/Question';
import { AnswerPerQuestion } from '../../interface/AnswersTable';
import { isInvalidNumberCondition } from '../../common/filter';
import { RotateLeft } from '@mui/icons-material';
import { initial } from 'lodash';

type FilterModalProps = {
  isOpen: boolean;
  filterConditions: FilterCondition[];
  sortConditions: SortCondition[];
  questions: ExistingQuestion[];
  handleClose: Function;
  handleCancelClose: Function;
  setFilterConditions: Dispatch<SetStateAction<FilterCondition[]>>;
  setPage: Dispatch<SetStateAction<number>>;
  fetchAnswers: Function;
  changeSearchParams: Function;
  setIsEnabledCheckForm: Dispatch<SetStateAction<boolean>>;
  checkboxOptions: EditingQuestionItem[] | AnswerPerQuestion[];
  targetQuestionId: number;
  resetFilter: Function;
  switchCheckState: Function;
  modalHeader: string;
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '90%',
  transform: 'translate(-90%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const boxStyle = {
  width: '100%',
  height: '100%',
  maxHeight: '300px',
  overflow: 'scroll',
  overflowX: 'hidden'
};

const FilterModal: React.FC<FilterModalProps> = (props) => {
  const [initialFilterConditions, setInitialFilterConditions] = useState<
    FilterCondition[]
  >([]);
  const [targetQuestionFilterConditions, setTargetQuestionFilterConditions] =
    useState<FilterCondition[]>([]);

  useEffect(() => {
    if (!props.isOpen) return;
    setInitialFilterConditions([...props.filterConditions]);

    const targetFilterConditions: FilterCondition[] =
      createTargetFilterConditions(props.filterConditions);

    if (targetFilterConditions.length === 0) {
      props.setFilterConditions([
        ...props.filterConditions,
        {
          filterTarget: props.targetQuestionId,
          condition: 'like',
          value: ''
        }
      ]);
      setTargetQuestionFilterConditions([
        {
          filterTarget: props.targetQuestionId,
          condition: 'like',
          value: ''
        }
      ]);
      return;
    }
  }, [props.isOpen]);

  const getTargetFilterConditionIndex = (
    targetFilterCondition: FilterCondition
  ): number =>
    props.filterConditions.findIndex(
      (filterCondition: FilterCondition) =>
        filterCondition.filterTarget === targetFilterCondition.filterTarget &&
        filterCondition.condition === targetFilterCondition.condition &&
        filterCondition.value === targetFilterCondition.value
    );

  const changeFilterCondition =
    (index: number) => (event: SelectChangeEvent) => {
      const targetFilterCondition: FilterCondition =
        targetQuestionFilterConditions.find((_, i: number) => i === index)!;

      const targetFilterConditionIndex: number = getTargetFilterConditionIndex(
        targetFilterCondition
      );

      const newFilterConditions: FilterCondition[] = props.filterConditions.map(
        (filterCondition: FilterCondition, i) => {
          if (i === targetFilterConditionIndex)
            return {
              ...targetFilterCondition,
              filterTarget:
                event.target.value === ''
                  ? targetFilterCondition.filterTarget
                  : props.targetQuestionId,
              condition: event.target.value as ConditionOption
            };
          return filterCondition;
        }
      );

      props.setFilterConditions(newFilterConditions);
      createTargetFilterConditions(newFilterConditions);
    };

  const changeFilterValue =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const targetFilterCondition: FilterCondition =
        targetQuestionFilterConditions.find((_, i: number) => i === index)!;

      const targetFilterConditionIndex: number = getTargetFilterConditionIndex(
        targetFilterCondition
      );

      const newFilterConditions: FilterCondition[] = props.filterConditions.map(
        (filterCondition: FilterCondition, i) => {
          if (i === targetFilterConditionIndex)
            return {
              ...targetFilterCondition,
              filterTarget:
                event.target.value === ''
                  ? targetFilterCondition.filterTarget
                  : props.targetQuestionId,
              value: event.target.value
            };
          return filterCondition;
        }
      );

      createTargetFilterConditions(newFilterConditions);
      props.setFilterConditions(newFilterConditions);
    };

  const addFilter = () => {
    const newFilterConditions: FilterCondition[] = [
      ...props.filterConditions,
      { filterTarget: props.targetQuestionId, condition: 'like', value: '' }
    ];

    props.setFilterConditions(newFilterConditions);
    createTargetFilterConditions(newFilterConditions);
  };

  const removeFilter = (index: number) => () => {
    const targetFilterCondition: FilterCondition =
      targetQuestionFilterConditions.find((_, i: number) => i === index)!;

    const targetFilterConditionIndex: number = getTargetFilterConditionIndex(
      targetFilterCondition
    );
    const newFilterConditions: FilterCondition[] =
      props.filterConditions.filter(
        (_, i: number) => targetFilterConditionIndex !== i
      );

    props.setFilterConditions(newFilterConditions);
    createTargetFilterConditions(newFilterConditions);
  };

  const createTargetFilterConditions = (
    filterConditions: FilterCondition[]
  ): FilterCondition[] => {
    const targetFilterConditions: FilterCondition[] = filterConditions.filter(
      (filterCondition: FilterCondition) =>
        filterCondition.filterTarget === props.targetQuestionId
    );

    setTargetQuestionFilterConditions(targetFilterConditions);
    return targetFilterConditions;
  };

  return (
    <Modal open={props.isOpen} id="filter-modal">
      <Box sx={modalStyle}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginBottom: 2 }}
        >
          <Typography variant="h6">{props.modalHeader}</Typography>
          <Button
            color="inherit"
            variant="outlined"
            className="detail-filter-reset"
            startIcon={<RotateLeft />}
            onClick={() => {
              props.resetFilter();
              setTargetQuestionFilterConditions([
                { filterTarget: 0, condition: 'like', value: '' }
              ]);
            }}
          >
            絞り込み条件リセット
          </Button>
        </Stack>
        <FormHelperText>
          この画面で設定した条件はAndで結合されます
        </FormHelperText>
        <Box sx={boxStyle}>
          {targetQuestionFilterConditions.map(
            (filterCondition: FilterCondition, index: number) => (
              <ConditionForms
                filterCondition={filterCondition}
                questions={props.questions}
                changeFilterCondition={changeFilterCondition(index)}
                changeFilterValue={changeFilterValue(index)}
                removeFilter={removeFilter(index)}
                key={index}
              />
            )
          )}
          <ButtonWithToolTip
            title="条件追加"
            onClick={addFilter}
            icon={<AddCircle />}
            className="filter-add"
          />
        </Box>
        <Stack
          spacing={2}
          direction="row"
          sx={{ marginTop: '0.5em', width: '100%', justifyContent: 'flex-end' }}
        >
          <Button
            className="detail-filter-close"
            variant="contained"
            onClick={() => {
              props.setFilterConditions([...initialFilterConditions]);
              props.switchCheckState(
                props.checkboxOptions,
                initialFilterConditions
              );
              props.handleCancelClose();
            }}
            color="secondary"
          >
            キャンセル
          </Button>
          <Button
            className="detail-filter-save"
            variant="contained"
            onClick={() => {
              props.changeSearchParams();
              props.setPage(0);
              props.fetchAnswers(0, props.sortConditions);
              props.handleClose();
              props.switchCheckState(
                props.checkboxOptions,
                props.filterConditions
              );
            }}
            disabled={props.filterConditions.some(
              (filterCondition: FilterCondition) =>
                isInvalidNumberCondition(filterCondition)
            )}
          >
            保存
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default FilterModal;
