import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIcon from '@mui/icons-material/Assignment';
import UploadIcon from '@mui/icons-material/Upload';
import { useNavigate } from 'react-router-dom';

const stackStyle = { ml: '8%', mr: '8%', mt: '8%' };
const buttonStyle = { mb: '10%', height: '3em', fontSize: '2em' };
const iconStyle = { fontSize: '1.7em', color: 'gainsboro' };

const TopMenuButtons: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{ padding: '2% 10% 0% 10%' }}
    >
      <Box sx={{ width: '100%' }}>
        <Stack sx={stackStyle}>
          <Button
            className="form-management-button"
            sx={buttonStyle}
            variant="contained"
            onClick={() => navigate('/form-management')}
            startIcon={<EditIcon style={iconStyle} />}
          >
            <b>フォーム作成・管理</b>
          </Button>
          <Typography variant="h5">
            作成済みのフォームの編集・複製や、
            <br></br>
            新しくフォームの作成ができます
            <br></br>
            フォームの編集権限管理もこちら
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Stack sx={stackStyle}>
          <Button
            className="form-answers-table-button"
            sx={buttonStyle}
            variant="contained"
            onClick={() => navigate('/form-answers-table')}
            startIcon={<AssignmentIcon style={iconStyle} />}
          >
            <b>フォーム回答一覧</b>
          </Button>
          <Typography variant="h5">
            フォームの回答一覧の確認・ダウンロードが行えます<br></br>
            一度回答した回答の編集もこちら
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Stack sx={stackStyle}>
          <Button
            className="file-io-button"
            sx={buttonStyle}
            variant="contained"
            onClick={() => navigate('/csv-upload')}
            startIcon={<UploadIcon style={iconStyle} />}
          >
            <b>回答一括アップロード</b>
          </Button>
          <Typography variant="h5">
            作成したフォームにcsv形式で回答を一括アップロードできます
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export default TopMenuButtons;
