import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { useEffect, useState } from 'react';
import QuotableAnswersTable from './QuotableAnswersTable';
import QuoteTargetFilterTextField from './QuoteTargetFilterTextField';
import { FilterCondition } from '../../interface/AnswersTable';
import { ERROR_COLOR } from '../../common/color';

type ReferenceToAnswerModalProps = {
  isOpen: boolean;
  handleClose: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  reflectAnswers: (metadataId: number) => () => void;
  quit: () => void;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80vw',
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const ReferenceToAnswerModal: React.FC<ReferenceToAnswerModalProps> = (
  props
) => {
  const [selectedMetadataId, setSelectedMetadataId] = useState<number>(0);
  const [filters, setFilters] = useState<FilterCondition[]>([]);

  useEffect(() => {
    if (!props.isOpen) {
      setSelectedMetadataId(0);
    }
  }, [props.isOpen]);

  const addFilter = (newFilter: FilterCondition) => {
    setFilters([...filters, newFilter]);
  };
  const removeFilter = (index: number) => {
    setFilters(filters.toSpliced(index, 1));
  };

  const changeSelectedMetadataId = (metadataId: number) => {
    setSelectedMetadataId(metadataId);
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={props.handleClose}
      id="answer-quote-modal"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          引用するデータを選択してください
        </Typography>
        <FormHelperText sx={{ color: ERROR_COLOR, marginLeft: '1em' }}>
          キー項目以外のデータはすべて上書きされます
        </FormHelperText>
        <QuoteTargetFilterTextField
          filters={filters}
          addFilter={addFilter}
          removeFilter={removeFilter}
        />
        <Button variant="outlined" onClick={() => setFilters([])}>
          フィルタ条件をクリア
        </Button>
        <QuotableAnswersTable
          isOpen={props.isOpen}
          changeSelectedMetadataId={changeSelectedMetadataId}
          selectedMetadataId={selectedMetadataId}
          filters={filters}
        />
        <Stack sx={{ marginTop: '0.5em' }} spacing={2} direction="row">
          <Button
            variant="contained"
            onClick={props.quit}
            color="secondary"
            className="confirm-no"
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={props.reflectAnswers(selectedMetadataId)}
            className="confirm-yes"
            disabled={selectedMetadataId === 0}
          >
            選択した回答を引用
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default ReferenceToAnswerModal;
