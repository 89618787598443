import {
  useEffect,
  useState,
  ChangeEvent,
  Dispatch,
  SetStateAction
} from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  AnswersTableHeader,
  AnswersTableMetaData,
  FilterCondition,
  SortCondition,
  AnswerPerQuestion,
  AnswersTableResponse
} from '../../interface/AnswersTable';
import {
  EditingQuestionItem,
  ExistingQuestion
} from '../../interface/Question';
import {
  createNeatFilterConditions,
  getFilterConditionKey,
  isInvalidNumberCondition,
  parseQueryParams
} from '../../common/filter';
import { useLocation, useSearchParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import { RotateLeft } from '@mui/icons-material';
import FilterModal from './FilterModal';
import { updateAnswer } from '../../common/answers-table';
import { fetchFilteredAnswers } from '../../api';
import { Typography } from '@mui/material';

type FilterSettingModalProps = {
  headers: AnswersTableHeader[];
  isOpen: boolean;
  filterConditions: FilterCondition[];
  sortConditions: SortCondition[];
  questions: ExistingQuestion[];
  handleClose: Function;
  targetQuestionId?: number;
  setFilterConditions: Dispatch<SetStateAction<FilterCondition[]>>;
  fetchAnswers: Function;
  isUnique: boolean;
  questionnaireId: number;
  limit: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
};

const boxStyle = {
  width: '100%',
  height: '100%',
  maxHeight: '300px',
  overflow: 'scroll',
  overflowX: 'hidden'
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '10%',
  transform: 'translate(-10%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const FilterCheckModal: React.FC<FilterSettingModalProps> = (props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const hash: string = location.pathname.split('/')[2];
  const [checkboxOptions, setCheckboxOptions] = useState<
    AnswerPerQuestion[] | EditingQuestionItem[]
  >([]);

  // セッションストレージから得たフィルタ条件を適用する
  const setPreviousFilterConditions = (
    previousFilterConditions?: FilterCondition[]
  ) => {
    if (previousFilterConditions !== undefined) {
      props.setFilterConditions(previousFilterConditions);
    } else {
      props.setFilterConditions([...props.filterConditions]);
    }
  };

  const [initialFilterConditions, setInitialFilterConditions] = useState<
    FilterCondition[]
  >([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [isEnabledDetailButton, setIsEnabledDetailButton] =
    useState<boolean>(true);
  const [isEnabledCheckForm, setIsEnabledCheckForm] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [noFilteredAnswers, setNoFilteredAnswers] = useState<
    AnswersTableResponse | undefined
  >(undefined);

  const handleFilterModalClose = () => {
    setIsFilterModalOpen(false);
    props.handleClose();
  };

  useEffect(() => {
    // location.stateがnullの場合、URL直打ちでエラーが起こる対策
    const previousPath: string | undefined =
      location.state === null
        ? undefined
        : (location.state.previousPath as string);
    // 回答編集画面から遷移した場合にセッションストレージから得たフィルタ条件を適応する
    if (previousPath === `/form-answer/${hash}`) {
      // セッションストレージからフィルタ条件の情報を得る
      const filterConditionsInSessionStorageString: string | null =
        sessionStorage.getItem(getFilterConditionKey(hash));

      // セッションストレージから得たフィルタ条件を配列型に変更する
      const previousFilterConditions: FilterCondition[] | undefined =
        filterConditionsInSessionStorageString === null
          ? undefined
          : JSON.parse(filterConditionsInSessionStorageString);
      setPreviousFilterConditions(previousFilterConditions);
      changeSearchParams(previousFilterConditions);
    } else {
      changeSearchParams(
        searchParams.toString() === ''
          ? undefined
          : parseQueryParams(searchParams)
      );
      setInitialFilterConditions([...props.filterConditions]);
    }
    sessionStorage.removeItem(getFilterConditionKey(hash));
  }, []);

  useEffect(() => {
    if (!props.isOpen) return;
    setInitialFilterConditions([...props.filterConditions]);

    if (props.targetQuestionId === undefined) {
      setCheckboxOptions([]);
      return;
    }

    createCheckboxOptions();
  }, [props.isOpen]);

  useEffect(() => {
    if (props.isOpen) createCheckboxOptions();
  }, [searchValue]);

  const getTargetQuestion = (): ExistingQuestion | undefined =>
    props.questions.find((question) => question.id === props.targetQuestionId);

  const getQuestionIndex = (): number =>
    props.headers.findIndex((header) => header.id === props.targetQuestionId);

  const getModalHeader = () => {
    const targetQuestion: ExistingQuestion | undefined = getTargetQuestion();

    if (targetQuestion === undefined) return '';

    const targetQuestionIndex: number = getQuestionIndex();

    return `[Q${targetQuestionIndex + 1}] ${targetQuestion.headline}`;
  };

  const createCheckboxOptions = async () => {
    const targetQuestion: ExistingQuestion = getTargetQuestion()!;

    const isDescriptionTarget: boolean =
      targetQuestion.type === 'text' || targetQuestion.type === 'number';

    if (isDescriptionTarget) {
      const targetQuestionIndex: number = getQuestionIndex();

      // フィルタがかかっていない場合の回答一覧をAPIで取得する
      const fetchedNoFilteredAnswers: AnswersTableResponse =
        noFilteredAnswers === undefined
          ? await fetchFilteredAnswers(
              props.questionnaireId,
              props.isUnique,
              props.limit,
              props.limit * props.page,
              [],
              []
            )
          : noFilteredAnswers;

      if (noFilteredAnswers === undefined)
        setNoFilteredAnswers(fetchedNoFilteredAnswers);

      const answersPerQuestion: AnswerPerQuestion[] =
        fetchedNoFilteredAnswers.answers
          .map(
            (answer: AnswersTableMetaData) =>
              updateAnswer(answer, props.headers).find(
                (_, index) => index === targetQuestionIndex
              )!
          )
          .filter(
            (
              option: AnswerPerQuestion,
              index: number,
              self: AnswerPerQuestion[]
            ) =>
              self.findIndex((check) => check.itemName === option.itemName) ===
              index
          );
      setCheckboxOptions(extractOptionsForSearchCondition(answersPerQuestion));
      switchDetailFilterButtonState(props.filterConditions, answersPerQuestion);
      switchCheckState(answersPerQuestion, props.filterConditions);
      return;
    }

    setCheckboxOptions(extractOptionsForSearchCondition(targetQuestion.items!));
    switchDetailFilterButtonState(
      props.filterConditions,
      targetQuestion.items!
    );
    switchCheckState(targetQuestion.items!, props.filterConditions);
  };

  const extractOptionsForSearchCondition = (
    options: AnswerPerQuestion[] | EditingQuestionItem[]
  ): AnswerPerQuestion[] | EditingQuestionItem[] =>
    options.filter((option: AnswerPerQuestion | EditingQuestionItem) =>
      'name' in option
        ? option.name.toLowerCase().includes(searchValue.toLowerCase())
        : option.itemName!.toLowerCase().includes(searchValue.toLowerCase())
    );

  const handleCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked: boolean = event.target.checked;
    const targetItemName: string = event.target.name;

    // チェックが入った場合
    if (isChecked) {
      setIsEnabledDetailButton(false);

      const defaultFilterConditionsExclusion: FilterCondition[] =
        props.filterConditions.filter(
          (filterCondition: FilterCondition) =>
            !(
              filterCondition.filterTarget === 0 &&
              filterCondition.condition === 'like' &&
              filterCondition.value === ''
            )
        );

      if (
        props.filterConditions.length > defaultFilterConditionsExclusion.length
      ) {
        props.setFilterConditions([
          ...defaultFilterConditionsExclusion,
          {
            filterTarget: props.targetQuestionId!,
            condition: 'strictEqual',
            value: targetItemName
          }
        ]);
        return;
      }

      props.setFilterConditions([
        ...props.filterConditions,
        {
          filterTarget: props.targetQuestionId!,
          condition: 'strictEqual',
          value: targetItemName
        }
      ]);
      return;
    }

    // チェックを外した場合
    const targetFilterConditionIndex: number = props.filterConditions.findIndex(
      (filterCondition: FilterCondition) =>
        filterCondition.filterTarget === props.targetQuestionId! &&
        filterCondition.condition === 'strictEqual' &&
        filterCondition.value === targetItemName
    )!;
    removeCheckFilter(targetFilterConditionIndex);
  };

  const removeCheckFilter = (index: number) => {
    const removedTargetFilterConditions = props.filterConditions.filter(
      (_, i: number) => index !== i
    );

    if (removedTargetFilterConditions.length === 0) {
      props.setFilterConditions([
        { filterTarget: 0, condition: 'like', value: '' }
      ]);
    } else {
      props.setFilterConditions(removedTargetFilterConditions);
    }

    switchDetailFilterButtonState(
      removedTargetFilterConditions,
      checkboxOptions
    );
  };

  const isCheckCondition = (
    filterCondition: FilterCondition,
    itemName: string
  ): boolean =>
    filterCondition.filterTarget === props.targetQuestionId! &&
    filterCondition.condition === 'strictEqual' &&
    filterCondition.value === itemName;

  const resetFilter = () => {
    const resetFilterConditions: FilterCondition[] =
      props.filterConditions.filter(
        (filterCondition: FilterCondition) =>
          filterCondition.filterTarget !== props.targetQuestionId
      );

    props.setFilterConditions([
      ...resetFilterConditions,
      { filterTarget: 0, condition: 'like', value: '' }
    ]);

    setIsEnabledCheckForm(true);
    setIsEnabledDetailButton(true);
  };

  const switchDetailFilterButtonState = (
    filterConditions: FilterCondition[] | undefined,
    checkboxOptions: EditingQuestionItem[] | AnswerPerQuestion[]
  ) => {
    if (filterConditions === undefined) return;

    const existCheckAtLeastOne: boolean = checkboxOptions.some(
      (option: EditingQuestionItem | AnswerPerQuestion) =>
        filterConditions.findIndex((filterCondition: FilterCondition) =>
          'name' in option
            ? isCheckCondition(filterCondition, option.name)
            : isCheckCondition(filterCondition, option.itemName!)
        ) !== -1
    );

    if (!existCheckAtLeastOne) {
      setIsEnabledDetailButton(true);
    } else {
      setIsEnabledDetailButton(false);
    }
  };

  const switchCheckState = (
    checkboxOptions: EditingQuestionItem[] | AnswerPerQuestion[],
    filterConditions: FilterCondition[]
  ) => {
    const targetQuestionFilterConditions: FilterCondition[] =
      filterConditions.filter(
        (filterCondition) =>
          filterCondition.filterTarget === props.targetQuestionId!
      );
    // 対象の質問に関するフィルタ条件がない場合はtrue
    if (targetQuestionFilterConditions.length === 0) {
      setIsEnabledCheckForm(true);
      return;
    }

    const isAllConditionsStrictEqual: boolean =
      targetQuestionFilterConditions.every(
        (filterCondition) =>
          filterCondition.condition === 'strictEqual' ||
          filterCondition.value === ''
      );

    if (!isAllConditionsStrictEqual) {
      setIsEnabledCheckForm(false);
      return;
    }

    const isEnabledCheck: boolean = targetQuestionFilterConditions.every(
      (filterCondition) =>
        checkboxOptions.findIndex((option) =>
          'name' in option
            ? filterCondition.value === option.name
            : filterCondition.value === option.itemName!
        ) !== -1
    );

    setIsEnabledCheckForm(isEnabledCheck);
  };

  const changeSearchParams = (previousFilterConditions?: FilterCondition[]) => {
    const filterConditions: FilterCondition[] =
      previousFilterConditions === undefined
        ? props.filterConditions
        : previousFilterConditions;
    const neatFilterConditions = createNeatFilterConditions(filterConditions);
    if (neatFilterConditions.length !== 0) {
      const queryParameters = createNeatFilterConditions(filterConditions).map(
        (filterConditions, filterIndex) => {
          const filterTargetKey: string = `filter[${filterIndex}][filterTarget]`;
          const conditionKey: string = `filter[${filterIndex}][condition]`;
          const valueKey: string = `filter[${filterIndex}][value]`;

          const filterTargetQueryParameter = `${filterTargetKey}=${JSON.stringify(
            filterConditions.filterTarget
          )}`;
          const conditionQueryParameter = `${conditionKey}=${filterConditions.condition}`;
          const valueQueryParameter = `${valueKey}=${filterConditions.value}`;
          return `${filterTargetQueryParameter}&${conditionQueryParameter}&${valueQueryParameter}`;
        }
      );
      setSearchParams(queryParameters.join('&'));
    } else {
      setSearchParams();
    }
  };

  return (
    <>
      <Modal open={props.isOpen} id="filter-modal">
        <Box sx={modalStyle}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {getModalHeader()}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ marginBottom: 2 }}
          >
            <Button
              className="filter-open"
              variant="outlined"
              size="large"
              startIcon={<FilterNoneIcon />}
              onClick={() => {
                setIsFilterModalOpen(true);
              }}
              disabled={!isEnabledDetailButton}
            >
              詳細フィルター
            </Button>
            {
              <Button
                color="inherit"
                variant="outlined"
                className="filter-reset"
                startIcon={<RotateLeft />}
                onClick={resetFilter}
              >
                絞り込み条件リセット
              </Button>
            }
          </Box>
          <Box sx={boxStyle}>
            {!isEnabledDetailButton ? (
              <FormHelperText>
                1つ以上チェックを入れている場合は詳細フィルターを設定できません
              </FormHelperText>
            ) : !isEnabledCheckForm ? (
              <FormHelperText>
                詳細フィルターで条件を設定している場合はチェックができません
              </FormHelperText>
            ) : (
              <></>
            )}
            <TextField
              onChange={(event) => setSearchValue(event.target.value)}
              value={searchValue}
              slotProps={{
                input: {
                  startAdornment: <SearchIcon></SearchIcon>,
                  endAdornment: (
                    <ButtonWithToolTip
                      title="クリア"
                      onClick={() => setSearchValue('')}
                      icon={<ClearIcon></ClearIcon>}
                    ></ButtonWithToolTip>
                  )
                }
              }}
            ></TextField>
            <FormGroup>
              {checkboxOptions.length === 0 ? (
                <Typography sx={{ mt: 2 }}>該当なし</Typography>
              ) : (
                checkboxOptions.map(
                  (
                    option: AnswerPerQuestion | EditingQuestionItem,
                    index: number
                  ) => {
                    const optionName: string =
                      'name' in option ? option.name : option.itemName!;
                    const isSetInFilterCondition: boolean =
                      props.filterConditions.findIndex(
                        (filterCondition) =>
                          filterCondition.filterTarget ===
                            props.targetQuestionId! &&
                          filterCondition.condition === 'strictEqual' &&
                          filterCondition.value === optionName
                      ) !== -1;
                    return (
                      <FormControlLabel
                        data-testid="filter-checkbox-form-label"
                        control={
                          <Checkbox
                            name={optionName}
                            onChange={handleCheckChange}
                            checked={isSetInFilterCondition}
                            disabled={!isEnabledCheckForm}
                          />
                        }
                        label={optionName}
                        key={index}
                      />
                    );
                  }
                )
              )}
            </FormGroup>
          </Box>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              marginTop: '0.5em',
              width: '100%',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              className="filter-close"
              variant="contained"
              onClick={() => {
                props.setFilterConditions([...initialFilterConditions]);
                setCheckboxOptions([]);
                props.handleClose();
                setNoFilteredAnswers(undefined);
                setSearchValue('');
              }}
              color="secondary"
            >
              キャンセル
            </Button>
            <Button
              className="filter-save"
              variant="contained"
              onClick={() => {
                changeSearchParams();
                props.setPage(0);
                props.fetchAnswers(0, props.sortConditions);
                setCheckboxOptions([]);
                props.handleClose();
                setNoFilteredAnswers(undefined);
                setSearchValue('');
              }}
              disabled={props.filterConditions.some(
                (filterCondition: FilterCondition) =>
                  isInvalidNumberCondition(filterCondition)
              )}
            >
              保存
            </Button>
          </Stack>
        </Box>
      </Modal>
      <FilterModal
        isOpen={isFilterModalOpen}
        filterConditions={props.filterConditions}
        questions={props.questions}
        handleClose={handleFilterModalClose}
        handleCancelClose={() => setIsFilterModalOpen(false)}
        setFilterConditions={props.setFilterConditions}
        setPage={props.setPage}
        fetchAnswers={props.fetchAnswers}
        sortConditions={props.sortConditions}
        changeSearchParams={changeSearchParams}
        setIsEnabledCheckForm={setIsEnabledCheckForm}
        checkboxOptions={checkboxOptions}
        targetQuestionId={props.targetQuestionId!}
        resetFilter={resetFilter}
        switchCheckState={switchCheckState}
        modalHeader={getModalHeader()}
      />
    </>
  );
};

export default FilterCheckModal;
