import axios, { AxiosRequestConfig } from 'axios';
import { QuestionResponse } from '../../interface/Question';
import {
  EditedQuestionnaire,
  Questionnaire
} from '../../interface/Questionnaire';
import { eventHeaders } from '../../common/auth';
import { API_BASE_URL } from '../constant';

export const fetchQuestions = async (
  idOrHash: number | string,
  isAll: boolean
): Promise<QuestionResponse> => {
  const requestParameter =
    typeof idOrHash === 'number'
      ? { questionnaireId: idOrHash, isAll }
      : { hash: idOrHash, isAll };
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.get(`${API_BASE_URL}/question`, {
    params: requestParameter,
    ...headers
  });

  return response.data;
};

export const postQuestionnaire = async (questionnaire: Questionnaire) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/question`,
    questionnaire,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }
};

export const updateQuestionnaire = async (
  questionnaire: EditedQuestionnaire
) => {
  const headers: AxiosRequestConfig = await eventHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/question`,
    questionnaire,
    headers
  );

  if (response.status >= 400) {
    throw new Error(response.data.message);
  }
};
